import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import io from 'socket.io-client';

import api from 'src/apiRequest';
import { WEBSOCKET_BASE_URL } from 'src/config';
import getReportNotificationTexts from 'src/contexts/AsyncServiceContext/getReportNotificationTexts';
import { hideToast, showToast } from 'src/store/ducks/toasts';

export const AsyncServiceContext = createContext(null);

export const AsyncServiceProvider = ({ children, setOrdersChanged }) => {
  const [socket, setSocket] = useState(null);

  const dispatch = useDispatch();

  const showReportLoadingToast = useCallback(
    ({ label, requestId, withPictures }) => {
      if (!requestId) return;

      let parsedLabel = `Compilando o ${label?.toLowerCase()}`;

      if (withPictures) parsedLabel += ' (com fotos)';

      parsedLabel += '. Você será notificado em breve.';

      dispatch(
        showToast({
          id: requestId,
          type: 'loading',
          title: 'Gerando relatório!',
          text: parsedLabel,
        })
      );
    },
    [dispatch]
  );

  const onNotification = useCallback(
    notification => {
      const { data, type } = notification;
      const requestId = data?.request_id;

      if (requestId) dispatch(hideToast(requestId));

      if (
        type === 'report' ||
        type === 'consolidated_summary' ||
        type === 'consolidated-post-paid_summary' ||
        type === 'post-paid_summary' ||
        type === 'account_summary' ||
        type === 'inspections_summary'
      ) {
        const { title, text } = getReportNotificationTexts(notification);

        dispatch(
          showToast({
            type: 'success',
            title,
            text,
            link: data?.url || null,
            onClick: data?.inspection_id ? () => saveHistory(data) : undefined,
          })
        );
      } else if (type === 'ordersChanged') {
        setOrdersChanged(true);
      }
    },
    [dispatch, setOrdersChanged]
  );

  const on = useCallback(
    (event, cb) => {
      if (!socket) return;
      socket.on(event, cb);
    },
    [socket]
  );

  const off = useCallback(
    (event, listener) => {
      if (!socket) return;
      socket.off(event, listener);
    },
    [socket]
  );

  const emit = useCallback(
    ({ event = 'NEW_MESSAGE', data = {} }) => {
      socket.emit(event, data);
    },
    [socket]
  );

  const saveHistory = ({ data, format = null }) => {
    const me = JSON.parse(window.sessionStorage.getItem('me') || {});

    if (me?.authRole === 'ROLE_CLIENT' && data?.inspection_id) {
      api.post(`/orders/${data.inspection_id}/report-download`, {
        tags: data?.tags || [],
        type: format,
      });
    }
  };

  useEffect(() => {
    const me = JSON.parse(window.sessionStorage.getItem('me'));
    const socketio = io(WEBSOCKET_BASE_URL, { path: '/ws/socket.io' });
    setSocket(socketio);
    window.socket = socketio;

    socketio.on('connect', () => {
      socketio.emit('join', {
        channels: [`individual_${me.uniqueId}`, `panel_${me.panelId}`],
      });

      setSocket(socketio);
    });

    socketio.on('notification', onNotification);

    return () => socketio.close();
  }, [onNotification]);

  return (
    <AsyncServiceContext.Provider value={{ off, emit, on, showReportLoadingToast }}>
      {children}
    </AsyncServiceContext.Provider>
  );
};

AsyncServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  setOrdersChanged: PropTypes.func.isRequired,
};

export function useAsyncService() {
  const context = useContext(AsyncServiceContext);

  if (!context) {
    throw new Error('useAsyncService must be used within an AsyncServiceProvider component');
  }

  return context;
}

export const AsyncServiceConsumer = AsyncServiceContext.Consumer;
