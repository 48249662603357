import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import API from 'src/apiRequest';
import ClientSummaryComponent from 'src/components/ClientsPage/ClientSummary/ClientSummaryComponent';
import classes from 'src/components/ClientsPage/ClientSummary/style.module.scss';

const summaryOptions = new Map([
  ['consolidated', 'CONSOLIDATED_EXTRACT'],
  ['summary', 'DETAILED_EXTRACT'],
  ['consolidated-post-paid', 'CONSOLIDATED_POST_PAID_EXTRACT'],
  ['post-paid', 'POST_PAID_EXTRACT'],
]);

const numberFormat = new Intl.NumberFormat('pt-BR', { maximumSignificantDigits: 5 });

const perPage = 15;

const ClientSummaryContainer = () => {
  const [items, setItems] = useState();
  const [pagination, setPagination] = useState();
  const [loading, setLoading] = useState(false);
  const [isExportingSummary, setIsExportingSummary] = useState(false);
  const [summaryType, setSummaryType] = useState('consolidated');
  const [isDropdownMenuVisible, setIsDropdownMenuVisible] = useState(false);
  const [query, setQuery] = useState({});

  const menuButtonRef = useRef(null);
  const intl = useIntl();
  const { id } = useParams();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const { data } = await API.get(`/clients/${id}/accounts/${summaryType}`, {
          params: {
            offset: 0,
            limit: perPage,
            order_by: 'created_at',
            order_by_dir: 'desc',
            start_date: query.startDate ? moment(query.startDate).format('YYYY-MM-DD') : null,
            end_date: query.endDate ? moment(query.endDate).format('YYYY-MM-DD') : null,
          },
        });
        setPagination({
          totalPages: Math.ceil(data.total / perPage),
          currentPage: 1,
          perPage,
        });
        setItems(data.data);
      } catch (err) {
        console.debug('ClientSummaryContainer fetchDetails error ', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [id, query.endDate, query.startDate, summaryType]);

  useEffect(() => {
    const handleClickOutside = evt => {
      if (menuButtonRef.current && !menuButtonRef.current.contains(evt.target)) {
        setIsDropdownMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const setPage = async page => {
    try {
      setLoading(true);
      const { data } = await API.get(`/clients/${id}/accounts/${summaryType}`, {
        params: {
          offset: (page - 1) * perPage,
          limit: perPage,
          order_by: 'created_at',
          order_by_dir: 'desc',
          start_date: query.startDate ? moment(query.startDate).format('YYYY-MM-DD') : null,
          end_date: query.endDate ? moment(query.endDate).format('YYYY-MM-DD') : null,
        },
      });
      setPagination({
        ...pagination,
        totalPages: Math.ceil(data.total / perPage),
        currentPage: page,
      });
      setItems(data.data);
      setLoading(false);
    } catch (err) {
      console.debug('ClientSummaryContainer setPage error ', err);
    }
  };

  const PageNumbers = () => {
    if (!pagination) return null;

    const pageNumbers = [];
    for (let i = 1; i <= pagination.totalPages; i++) {
      pageNumbers.push(i);
    }

    let offset = 4;

    if (pagination.currentPage - 4 < 1) {
      offset += 4 - (pagination.currentPage - 1);
    } else if (pagination.currentPage + 4 > pagination.totalPages) {
      offset += 4 - (pagination.totalPages - pagination.currentPage);
    }

    return pageNumbers.map(number => {
      const pageClass = number === pagination.currentPage ? classes.isActive : null;

      if (
        pagination.totalPages < 10 || // show all pages if less than 10
        number === 1 || // first page
        number === pagination.totalPages || // last page
        (number > pagination.currentPage - offset && number < pagination.currentPage + offset) // show 4 items before and after
      ) {
        return (
          <button type="button" className={`${classes.page} ${pageClass}`} key={number} onClick={() => setPage(number)}>
            {number}
          </button>
        );
      }
      if (number === 2) {
        return (
          <div className={classes.omission} key="start-dots">
            …
          </div>
        );
      }
      if (number === pagination.totalPages - 1) {
        return (
          <div className={classes.omission} key="end-dots">
            …
          </div>
        );
      }
      return null;
    });
  };

  const exportSummary = () => {
    const type = summaryType === 'summary' ? 'account' : summaryType;

    setIsExportingSummary(true);

    API.get(`/clients/${id}/accounts/export/${type}`, {
      params: {
        start_date: query.startDate || null,
        end_date: query.endDate || null,
      },
    })
      .catch(err => {
        console.debug('ClientSummaryContainer exportSummary error ', err);
      })
      .finally(() => {
        setIsExportingSummary(false);
      });
  };

  const handleFilter = filter => {
    setQuery(filter);
  };

  return (
    <ClientSummaryComponent
      exportSummary={exportSummary}
      handleFilter={handleFilter}
      id={id}
      intl={intl}
      isDropdownMenuVisible={isDropdownMenuVisible}
      isExportingSummary={isExportingSummary}
      items={items}
      loading={loading}
      menuButtonRef={menuButtonRef}
      numberFormat={numberFormat}
      pageNumbers={PageNumbers}
      pagination={pagination}
      query={query}
      setIsDropdownMenuVisible={setIsDropdownMenuVisible}
      setPage={setPage}
      setSummaryType={setSummaryType}
      summaryType={summaryType}
      summaryOptions={summaryOptions}
    />
  );
};

export default ClientSummaryContainer;
