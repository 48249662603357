import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-jsonschema-form';

import { Grid } from '@material-ui/core';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

import API from 'src/apiRequest';
import UserContext from 'src/contexts/UserContext';
import { dateTimePattern } from 'src/helpers/isValidDateTimeFormat';
import removeDuplicates from 'src/helpers/removeDuplicates';
import Loading from 'src/Loading';
import ArrayFieldTemplate from 'src/orderForm/fields/ArrayFieldTemplate';
import AttachmentField from 'src/orderForm/fields/AttachmentField';
import AttachmentFiles from 'src/orderForm/fields/AttachmentFiles';
import AutoCompleteOrCreate from 'src/orderForm/fields/AutoCompleteOrCreate';
import DocuSignFile from 'src/orderForm/fields/DocuSignFile';
import DocuSignSignersField from 'src/orderForm/fields/DocuSignSigners';
import EditRequestFormFieldDisable from 'src/orderForm/fields/EditRequestFormFieldDisable';
import FileUpload from 'src/orderForm/fields/FileUpload';
import KeysLocationField from 'src/orderForm/fields/KeysLocationField';
import RealEstateAdressesSelectField from 'src/orderForm/fields/RealEstateAdressesSelectField';
import RecipientsField from 'src/orderForm/fields/RecipientsField/RecipientsField';
import CustomFieldDisable from 'src/orderForm/fields/RequestFormFieldDisable';
import People from 'src/orderForm/fields/RequestFormPeople';
import Person from 'src/orderForm/fields/RequestFormPerson';
import RequestFormPreviousFile from 'src/orderForm/fields/RequestFormPreviousFile';
import RequestFormPreviousPanel from 'src/orderForm/fields/RequestFormPreviousPanel';
import SuitorsArrayFieldTemplate from 'src/orderForm/fields/SuitorsArrayFieldTemplate';
import TagsFieldTemplate from 'src/orderForm/fields/TagsFieldTemplate';
import makeWidgets from 'src/orderForm/OrderForm/makeWidgets';
import * as OrderFormTitleIcons from 'src/orderForm/OrderForm/OrderFormTitleIcons';
import containerClasses from 'src/orderForm/style.module.scss';
import AddressService from 'src/services/addressService';

class OrderForm extends Component {
  static contextType = UserContext;

  styles = {
    title: {
      fontFamily: 'Open Sans',
      fontWeight: 'bold',
      fontSize: 'calc(10px + 0.4vw)',
    },
    content: {
      paddingLeft: '1vw',
      paddingRight: '1vw',
    },
    error: {
      fontFamily: 'Open Sans',
      fontWeight: 'normal',
      fontSize: 'calc(6px + 0.3vw)',
    },
  };

  customFormats = { 'date-with-time': dateTimePattern };

  transformErrorsMessages = {
    required: 'requiredField',
    minItems: 'DocuSign.errors.minItems',
    minLength: 'minLength',
    format: 'invalidFormat',
    type: 'invalidFormat',
  };

  objectFieldTemplateForbiddenTitles = ['Administradora', 'Captador', 'Construtora', 'Vistoriador'];

  objectFieldTemplateClassNames = {
    simple: 'col-md-4',
    foursided: 'col-md-4',
    others: 'col-md-4',
    complement: 'col-md-4',
    number: 'col-md-4',
    state: 'col-md-4',
    condominiumCost: 'col-md-4',
    iptuCost: 'col-md-4',
    rentCost: 'col-md-4',
    companionName: 'col-md-4',
    companionContact: 'col-md-4',
    scheduleDate: 'col-md-4',
    area: 'col-md-6',
    furnished: 'col-md-6',
    building_type: 'col-md-6',
    purpose: 'col-md-6',
    link: 'col-md-6',
    highlights: 'col-md-6',
    fullName: 'col-md-6',
    whatsapp: 'col-md-6',
    date1: 'col-md-6',
    time1: 'col-md-6',
    date2: 'col-md-6',
    time2: 'col-md-6',
    roomsCount: 'col-md-6',
    suitesCount: 'col-md-6',
    contact: 'col-md-6',
    name: 'col-md-6',
    street: 'col-md-8',
    neighborhood: 'col-md-8',
    city: 'col-md-8',
    property_code: 'col-md-6',
    real_estate_type: 'col-md-6',
    rent_value: 'col-md-4',
    iptu_value: 'col-md-4',
    condominium_value: 'col-md-4',
    income_origin: 'col-md-6',
    income_value: 'col-md-6',
    suitor_name: 'col-md-6',
    suitor_natural_document: 'col-md-6',
    suitor_legal_document: 'col-md-6',
    reside_or_participant: 'col-md-12',
    income: 'container-fluid p-0',
    main: 'container-fluid p-0',
  };

  customTitleFieldIcons = {
    parties: <OrderFormTitleIcons.InvolvedParties viewBox="0 0 34.016 34.016" />,
    keys: <OrderFormTitleIcons.KeysIcon viewBox="0 0 34.016 34.016" />,
    mainAddress: <OrderFormTitleIcons.Address viewBox="0 0 34.016 34.016" />,
    location_references: <OrderFormTitleIcons.Address viewBox="0 0 34.016 34.016" />,
    details: <OrderFormTitleIcons.Details viewBox="0 0 34.016 34.016" />,
    construction_details: <OrderFormTitleIcons.Details viewBox="0 0 34.016 34.016" />,
    solicitations: <OrderFormTitleIcons.Details viewBox="0 0 34.016 34.016" />,
    photoBuildingDetails: <OrderFormTitleIcons.Details viewBox="0 0 34.016 34.016" />,
    client: <OrderFormTitleIcons.Client viewBox="0 0 34.016 34.016" />,
    clients: <OrderFormTitleIcons.Client viewBox="0 0 34.016 34.016" />,
    franchisees: <OrderFormTitleIcons.Franchisee viewBox="0 0 34.016 34.016" />,
    previous: <OrderFormTitleIcons.Previous viewBox="0 0 34.016 34.016" />,
    additionals: <OrderFormTitleIcons.Previous viewBox="0 0 34.016 34.016" />,
    cost: <OrderFormTitleIcons.Previous viewBox="0 0 34.016 34.016" />,
    recommendation: <OrderFormTitleIcons.Recommendation />,
    tags: <OrderFormTitleIcons.Tag />,
    accessInformation: <OrderFormTitleIcons.AccessInformation />,
    accompaniedInspection: <OrderFormTitleIcons.AccompaniedInspection />,
    propertyInformations: <OrderFormTitleIcons.PropertyInformations />,
    propertyAccess: <OrderFormTitleIcons.PropertyAccess />,
    interestedPerson: <OrderFormTitleIcons.InterestedPerson />,
    potentialSchedule: <OrderFormTitleIcons.PotentialSchedule />,
    additionalCosts: <OrderFormTitleIcons.AdditionalCosts />,
    reportSubmission: <OrderFormTitleIcons.ReportSubmission />,
    vacationNotice: <OrderFormTitleIcons.ReportSubmission />,
    desired_property: <OrderFormTitleIcons.Property />,
    main_suitor: <OrderFormTitleIcons.Client />,
    main_suitor_address: <OrderFormTitleIcons.Property />,
    suitors: <OrderFormTitleIcons.ClientPlus />,
    budgetDuringInspection: <OrderFormTitleIcons.BudgetDuringInspection />,
  };

  keySelectionTypesToShowAddressFields = ['Na imobiliária', 'Outro'];

  constructor(props, context) {
    super(props, context);

    this.state = {
      fields: {
        person: Person,
        people: People,
        TitleField: this.customTitleField,
        customDisableField: CustomFieldDisable,
        customPreviousFile: RequestFormPreviousFile,
        customPreviousPanel: RequestFormPreviousPanel,
        customEditDisableField: EditRequestFormFieldDisable,
        customFileUpload: FileUpload,
        autoCompleteOrCreate: AutoCompleteOrCreate,
        autoCompleteOrCreateAddress: AutoCompleteOrCreate,
        attachments: AttachmentField,
        docusignFile: DocuSignFile,
        attachmentFiles: AttachmentFiles,
        docuSignSignersField: DocuSignSignersField,
        keysLocationField: KeysLocationField,
        realEstateAdressesSelectField: RealEstateAdressesSelectField,
        recipientsField: RecipientsField,
      },

      loading: true,
      isOrderEdition: false,

      formData: {},
      uiSchema: {},
      schema: {},
      minimalPrice: null,
      priceCalculated: false,
      priceType: null,

      addressesStates: [],

      addressCities: [],
      addressNeighborhoods: [],
      addressSelectedState: null,
      addressSelectedCity: null,
      addressSelectedNeighborhood: null,

      accessInformationAddressCities: [],
      accessInformationAddressNeighborhoods: [],
      accessInformationAddressSelectedState: null,
      accessInformationAddressSelectedCity: null,
      accessInformationAddressSelectedNeighborhood: null,

      propertyAccessAddressCities: [],
      propertyAccessAddressNeighborhoods: [],
      propertyAccessAddressSelectedState: null,
      propertyAccessAddressSelectedCity: null,
      propertyAccessAddressSelectedNeighborhood: null,

      accessInformationKeysDevolutionAddressCities: [],
      accessInformationKeysDevolutionAddressNeighborhoods: [],
      accessInformationKeysDevolutionAddressSelectedState: null,
      accessInformationKeysDevolutionAddressSelectedCity: null,
      accessInformationKeysDevolutionAddressSelectedNeighborhood: null,

      propertyAccessKeysDevolutionAddressCities: [],
      propertyAccessKeysDevolutionAddressNeighborhoods: [],
      propertyAccessKeysDevolutionAddressSelectedState: null,
      propertyAccessKeysDevolutionAddressSelectedCity: null,
      propertyAccessKeysDevolutionAddressSelectedNeighborhood: null,

      desiredPropertyAddressCities: [],
      desiredPropertyAddressNeighborhoods: [],
      desiredPropertyAddressSelectedState: null,
      desiredPropertyAddressSelectedCity: null,
      desiredPropertyAddressSelectedNeighborhood: null,

      mainSuitorAddressCities: [],
      mainSuitorAddressNeighborhoods: [],
      mainSuitorAddressSelectedState: null,
      mainSuitorAddressSelectedCity: null,
      mainSuitorAddressSelectedNeighborhood: null,

      realEstateAddresses: [],

      changeAccessInformationAddressFieldsAutomatically: false,
      changePropertyAccessAddressFieldsAutomatically: false,
      changeAccessInformationkeysDevolutionAddressFieldsAutomatically: false,
      changePropertyAccesskeysDevolutionAddressFieldsAutomatically: false,
    };
    this.addressService = new AddressService(context?.profile?.country);
    this.onChangeTimeout = null;
  }

  setAddressSelectedFields = formData => {
    const selectedAddressesFields = {};

    const processAddressFields = (path, nestedPath = '', prefix = '') => {
      const address = nestedPath ? formData?.[path]?.[nestedPath] : formData?.[path];

      if (address) {
        const { state = '', city = '', neighborhood = '' } = address;

        const statePropName = !prefix ? 'addressSelectedState' : `${prefix}AddressSelectedState`;
        const cityPropName = !prefix ? 'addressSelectedCity' : `${prefix}AddressSelectedCity`;
        const neighborhoodPropName = !prefix ? 'addressSelectedNeighborhood' : `${prefix}AddressSelectedNeighborhood`;

        selectedAddressesFields[statePropName] = { label: state, value: state };
        selectedAddressesFields[cityPropName] = { label: city, value: city };
        selectedAddressesFields[neighborhoodPropName] = { label: neighborhood, value: neighborhood };
      }
    };

    try {
      processAddressFields('address');
      processAddressFields('accessInformation', 'address', 'accessInformation');
      processAddressFields('accessInformation', 'keysDevolutionAddress', 'accessInformationKeysDevolution');
      processAddressFields('propertyAccess', 'address', 'propertyAccess');
      processAddressFields('propertyAccess', 'keysDevolutionAddress', 'propertyAccessKeysDevolution');
      processAddressFields('desired_property', 'address', 'desiredProperty');
      processAddressFields('main_suitor_address', '', 'main_suitor');

      Object.keys(selectedAddressesFields).length && this.setState(selectedAddressesFields);
    } catch (err) {
      console.debug('[DEBUG] setAddressSelectedFields error ', err);
    }
  };

  async componentDidMount() {
    const { formData, orderType, previousOrderFile, schema, uiSchema } = this.props;
    const { formData: formDataState } = this.state;
    const me = this.context;

    const fetchedStates = this.addressService.getStates();
    const isOrderEdition = !!Object.keys(formData || {}).length;

    if (isOrderEdition) this.setState({ addressesStates: fetchedStates });

    const hasBudgetDuringInspectionSectionAllowed =
      me?.features?.includes('FORM_BUDGET_DURING_INSPECTION') && orderType === 'saida';

    if (
      !hasBudgetDuringInspectionSectionAllowed &&
      schema?.definitions?.budgetDuringInspection &&
      schema?.properties?.budgetDuringInspection
    ) {
      delete schema.definitions.budgetDuringInspection;
      delete schema.properties.budgetDuringInspection;

      schema.additionalProperties = true;
    }

    if (uiSchema?.tags) {
      uiSchema.tags.tags['ui:ArrayFieldTemplate'] = () => {
        return TagsFieldTemplate({
          handleChange: this.handleTagChange,
          schema,
          values: formData?.tags ? formData.tags?.tags || null : null,
        });
      };
    }

    if (uiSchema.franchisees) uiSchema.franchisees = { 'ui:widget': 'hidden', franchisee: { 'ui:widget': 'hidden' } };

    if (orderType === 'acompanhamentoObraMM') {
      const uiOrder = uiSchema['ui:order'];
      const exclude_list = [
        'keys',
        'cost',
        'additionals',
        'accessInformation',
        'accompaniedInspection',
        'recommendation',
      ];
      uiSchema['ui:order'] = uiOrder.filter(order => exclude_list.indexOf(order) === -1);
    }

    if (orderType !== 'acompanhamentoObraMM') {
      const uiOrder = uiSchema['ui:order'];
      const exclude_list = ['construction_details', 'solicitations'];
      uiSchema['ui:order'] = uiOrder.filter(order => exclude_list.indexOf(order) === -1);
    }

    if (orderType === 'cautelarQueirozGalvao') {
      const uiOrder = uiSchema['ui:order'];
      const exclude_list = ['accessInformation', 'accompaniedInspection'];
      uiSchema['ui:order'] = uiOrder.filter(order => exclude_list.indexOf(order) === -1);
    }

    if (orderType !== 'cautelarQueirozGalvao') {
      const uiOrder = uiSchema['ui:order'];
      const exclude_list = ['location_references'];
      uiSchema['ui:order'] = uiOrder.filter(order => exclude_list.indexOf(order) === -1);
    }

    if (previousOrderFile) {
      const initialState = {
        loading: false,
        isOrderEdition,
        uiSchema,
        schema,
        formData: update(formDataState, { previous_inspection: { $set: previousOrderFile } }),
        addressesStates: fetchedStates,
      };

      if (initialState.schema?.definitions?.address) {
        initialState.schema = update(initialState.schema, {
          definitions: {
            address: {
              properties: {
                state: {
                  enum: { $set: fetchedStates?.map(option => option?.name) || [] },
                  enumNames: { $set: fetchedStates?.map(option => option?.name) || [] },
                },
              },
            },
          },
        });
      }

      this.setState(initialState);

      return;
    }

    if (isOrderEdition) this.setAddressSelectedFields(formData);

    let updatedOrderPriceData = {};

    let formDataWithUpdatedAddressesFields = { ...formData };
    let schemaWithUpdatedAddressesFields = { ...schema };

    if (isOrderEdition && schema.definitions?.address) {
      updatedOrderPriceData = await this.calculateOrderPrice(formData, uiSchema);

      try {
        const handleSetAddressDataInState = ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.address?.zipcode,
            street: formData.address?.street || address?.street,
            neighborhood: formData.address?.neighborhood || address?.neighborhood,
            city: formData.address?.city || address?.city,
            state: formData.address?.state || address?.state,
            number: formData.address?.number,
            complement: formData.address?.complement,
          };

          const currentUserNeighborhood = formData.address?.neighborhood;
          const neighborhoodOptions = fetchedNeighborhoods?.map(option => option?.name) || [];

          if (
            currentUserNeighborhood &&
            !neighborhoodOptions.find(neighborhood => neighborhood === currentUserNeighborhood)
          ) {
            updatedAddress.neighborhood = currentUserNeighborhood;

            neighborhoodOptions.unshift(currentUserNeighborhood);
            fetchedNeighborhoods.unshift(this.createOption(currentUserNeighborhood));

            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          } else if (currentUserNeighborhood) {
            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          }

          const updatedFormData = update(formDataWithUpdatedAddressesFields, { address: { $set: updatedAddress } });

          const updatedSchema = update(schemaWithUpdatedAddressesFields, {
            definitions: {
              address: {
                properties: {
                  state: {
                    enum: { $set: fetchedStates?.map(option => option?.name) || [] },
                    enumNames: { $set: fetchedStates?.map(option => option?.name) || [] },
                  },
                  city: {
                    enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                    enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                  },
                  neighborhood: { enum: { $set: neighborhoodOptions }, enumNames: { $set: neighborhoodOptions } },
                },
              },
            },
          });

          formDataWithUpdatedAddressesFields = { ...updatedFormData };
          schemaWithUpdatedAddressesFields = { ...updatedSchema };

          this.setState({
            addressCities: fetchedCities,
            addressNeighborhoods: fetchedNeighborhoods,
            addressSelectedState: selectedState,
            addressSelectedCity: selectedCity,
            addressSelectedNeighborhood: selectedNeighborhood,
            formData: formDataWithUpdatedAddressesFields,
            schema: schemaWithUpdatedAddressesFields,
          });
        };

        // Address zipcode
        if (formData?.address?.zipcode) {
          await this.handleZipcodeFieldChange({
            zipcode: formData.address.zipcode,
            address: formData.address,
            handleSetAddressDataInState,
          });
        } else {
          await this.handleSetAddressByOrderData({ address: formData?.address, handleSetAddressDataInState });
        }

        const handleSetAccessInformationAddressDataInState = ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.accessInformation.address?.zipcode,
            street: formData.accessInformation.address?.street || address?.street,
            neighborhood: formData.accessInformation.address?.neighborhood || address?.neighborhood,
            city: formData.accessInformation.address?.city || address?.city,
            state: formData.accessInformation.address?.state || address?.state,
            number: formData.accessInformation.address?.number,
            complement: formData.accessInformation.address?.complement,
          };

          const currentUserNeighborhood = formData.accessInformation.address?.neighborhood;
          const neighborhoodOptions = fetchedNeighborhoods?.map(option => option?.name) || [];

          if (
            currentUserNeighborhood &&
            !neighborhoodOptions.find(neighborhood => neighborhood === currentUserNeighborhood)
          ) {
            updatedAddress.neighborhood = currentUserNeighborhood;

            neighborhoodOptions.unshift(currentUserNeighborhood);
            fetchedNeighborhoods.unshift(this.createOption(currentUserNeighborhood));

            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          } else if (currentUserNeighborhood) {
            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          }

          const updatedFormData = update(formDataWithUpdatedAddressesFields, {
            accessInformation: { address: { $set: updatedAddress } },
          });

          const updatedSchema = update(schemaWithUpdatedAddressesFields, {
            definitions: {
              accessInformation: {
                properties: {
                  address: {
                    $set: {
                      properties: {
                        ...schema.definitions.address.properties,
                        state: {
                          enum: fetchedStates?.map(option => option?.name) || [],
                          enumNames: fetchedStates?.map(option => option?.name) || [],
                        },
                        city: {
                          enum: fetchedCities?.map(option => option?.name) || [],
                          enumNames: fetchedCities?.map(option => option?.name) || [],
                        },
                        neighborhood: { enum: neighborhoodOptions, enumNames: neighborhoodOptions },
                      },
                    },
                  },
                },
              },
            },
          });

          formDataWithUpdatedAddressesFields = { ...updatedFormData };
          schemaWithUpdatedAddressesFields = { ...updatedSchema };

          this.setState({
            accessInformationAddressCities: fetchedCities,
            accessInformationAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationAddressSelectedState: selectedState,
            accessInformationAddressSelectedCity: selectedCity,
            accessInformationAddressSelectedNeighborhood: selectedNeighborhood,
            formData: formDataWithUpdatedAddressesFields,
            schema: schemaWithUpdatedAddressesFields,
          });
        };

        // AccessInformation address zipcode
        if (
          formData?.accessInformation?.address?.zipcode &&
          this.keySelectionTypesToShowAddressFields.includes(formData?.accessInformation?.type)
        ) {
          await this.handleZipcodeFieldChange({
            zipcode: formData.accessInformation.address.zipcode,
            address: formData.accessInformation.address,
            handleSetAddressDataInState: handleSetAccessInformationAddressDataInState,
          });
        } else if (this.keySelectionTypesToShowAddressFields.includes(formData?.accessInformation?.type)) {
          await this.handleSetAddressByOrderData({
            address: formData?.accessInformation?.address,
            handleSetAddressDataInState: handleSetAccessInformationAddressDataInState,
          });
        }

        const handleSetAccessInformationKeysDevolutionAddressDataInState = ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.accessInformation.keysDevolutionAddress?.zipcode,
            street: formData.accessInformation.keysDevolutionAddress?.street || address?.street,
            neighborhood: formData.accessInformation.keysDevolutionAddress?.neighborhood || address?.neighborhood,
            city: formData.accessInformation.keysDevolutionAddress?.city || address?.city,
            state: formData.accessInformation.keysDevolutionAddress?.state || address?.state,
            number: formData.accessInformation.keysDevolutionAddress?.number,
            complement: formData.accessInformation.keysDevolutionAddress?.complement,
          };

          const currentUserNeighborhood = formData.accessInformation.keysDevolutionAddress?.neighborhood;
          const neighborhoodOptions = fetchedNeighborhoods?.map(option => option?.name) || [];

          if (
            currentUserNeighborhood &&
            !neighborhoodOptions.find(neighborhood => neighborhood === currentUserNeighborhood)
          ) {
            updatedAddress.neighborhood = currentUserNeighborhood;

            neighborhoodOptions.unshift(currentUserNeighborhood);
            fetchedNeighborhoods.unshift(this.createOption(currentUserNeighborhood));

            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          } else if (currentUserNeighborhood) {
            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          }

          const updatedFormData = update(formDataWithUpdatedAddressesFields, {
            accessInformation: { keysDevolutionAddress: { $set: updatedAddress } },
          });

          const updatedSchema = update(schemaWithUpdatedAddressesFields, {
            definitions: {
              accessInformation: {
                properties: {
                  keysDevolutionAddress: {
                    $set: {
                      properties: {
                        ...schema.definitions.address.properties,
                        state: {
                          enum: fetchedStates?.map(option => option?.name) || [],
                          enumNames: fetchedStates?.map(option => option?.name) || [],
                        },
                        city: {
                          enum: fetchedCities?.map(option => option?.name) || [],
                          enumNames: fetchedCities?.map(option => option?.name) || [],
                        },
                        neighborhood: { enum: neighborhoodOptions, enumNames: neighborhoodOptions },
                      },
                    },
                  },
                },
              },
            },
          });

          formDataWithUpdatedAddressesFields = { ...updatedFormData };
          schemaWithUpdatedAddressesFields = { ...updatedSchema };

          this.setState({
            accessInformationKeysDevolutionAddressCities: fetchedCities,
            accessInformationKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationKeysDevolutionAddressSelectedState: selectedState,
            accessInformationKeysDevolutionAddressSelectedCity: selectedCity,
            accessInformationKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,
            formData: formDataWithUpdatedAddressesFields,
            schema: schemaWithUpdatedAddressesFields,
          });
        };

        // AccessInformation keys devolution address zipcode
        if (
          formData?.accessInformation?.keysDevolutionAddress?.zipcode &&
          this.keySelectionTypesToShowAddressFields.includes(formData?.accessInformation?.keysDevolutionType)
        ) {
          await this.handleZipcodeFieldChange({
            zipcode: formData.accessInformation.keysDevolutionAddress.zipcode,
            address: formData.accessInformation.keysDevolutionAddress,
            handleSetAddressDataInState: handleSetAccessInformationKeysDevolutionAddressDataInState,
          });
        } else if (
          this.keySelectionTypesToShowAddressFields.includes(formData?.accessInformation?.keysDevolutionType)
        ) {
          await this.handleSetAddressByOrderData({
            address: formData.accessInformation.keysDevolutionAddress,
            handleSetAddressDataInState: handleSetAccessInformationKeysDevolutionAddressDataInState,
          });
        }

        const handleSetPropertyAccessAddressDataInState = ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.propertyAccess.address?.zipcode,
            street: formData.propertyAccess.address?.street || address?.street,
            neighborhood: formData.propertyAccess.address?.neighborhood || address?.neighborhood,
            city: formData.propertyAccess.address?.city || address?.city,
            state: formData.propertyAccess.address?.state || address?.state,
            number: formData.propertyAccess.address?.number,
            complement: formData.propertyAccess.address?.complement,
          };

          const currentUserNeighborhood = formData.propertyAccess.address?.neighborhood;
          const neighborhoodOptions = fetchedNeighborhoods?.map(option => option?.name) || [];

          if (
            currentUserNeighborhood &&
            !neighborhoodOptions.find(neighborhood => neighborhood === currentUserNeighborhood)
          ) {
            updatedAddress.neighborhood = currentUserNeighborhood;

            neighborhoodOptions.unshift(currentUserNeighborhood);
            fetchedNeighborhoods.unshift(this.createOption(currentUserNeighborhood));

            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          } else if (currentUserNeighborhood) {
            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          }

          const updatedFormData = update(formDataWithUpdatedAddressesFields, {
            propertyAccess: { address: { $set: updatedAddress } },
          });

          const updatedSchema = update(schemaWithUpdatedAddressesFields, {
            definitions: {
              propertyAccess: {
                properties: {
                  address: {
                    $set: {
                      properties: {
                        ...schema.definitions.address.properties,
                        state: {
                          enum: fetchedStates?.map(option => option?.name) || [],
                          enumNames: fetchedStates?.map(option => option?.name) || [],
                        },
                        city: {
                          enum: fetchedCities?.map(option => option?.name) || [],
                          enumNames: fetchedCities?.map(option => option?.name) || [],
                        },
                        neighborhood: { enum: neighborhoodOptions, enumNames: neighborhoodOptions },
                      },
                    },
                  },
                },
              },
            },
          });

          formDataWithUpdatedAddressesFields = { ...updatedFormData };
          schemaWithUpdatedAddressesFields = { ...updatedSchema };

          this.setState({
            propertyAccessAddressCities: fetchedCities,
            propertyAccessAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessAddressSelectedState: selectedState,
            propertyAccessAddressSelectedCity: selectedCity,
            propertyAccessAddressSelectedNeighborhood: selectedNeighborhood,
            formData: formDataWithUpdatedAddressesFields,
            schema: schemaWithUpdatedAddressesFields,
          });
        };

        // PropertyAccess address zipcode
        if (
          formData?.propertyAccess?.address?.zipcode &&
          this.keySelectionTypesToShowAddressFields.includes(formData?.propertyAccess?.type)
        ) {
          await this.handleZipcodeFieldChange({
            zipcode: formData.propertyAccess.address.zipcode,
            address: formData.propertyAccess.address,
            handleSetAddressDataInState: handleSetPropertyAccessAddressDataInState,
          });
        } else if (this.keySelectionTypesToShowAddressFields.includes(formData?.propertyAccess?.type)) {
          await this.handleSetAddressByOrderData({
            address: formData?.propertyAccess?.address,
            handleSetAddressDataInState: handleSetPropertyAccessAddressDataInState,
          });
        }

        const handleSetPropertyKeysDevolutionAddressDataInState = ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.propertyAccess.keysDevolutionAddress?.zipcode,
            street: formData.propertyAccess.keysDevolutionAddress?.street || address?.street,
            neighborhood: formData.propertyAccess.keysDevolutionAddress?.neighborhood || address?.neighborhood,
            city: formData.propertyAccess.keysDevolutionAddress?.city || address?.city,
            state: formData.propertyAccess.keysDevolutionAddress?.state || address?.state,
            number: formData.propertyAccess.keysDevolutionAddress?.number,
            complement: formData.propertyAccess.keysDevolutionAddress?.complement,
          };

          const currentUserNeighborhood = formData.propertyAccess.keysDevolutionAddress?.neighborhood;
          const neighborhoodOptions = fetchedNeighborhoods?.map(option => option?.name) || [];

          if (
            currentUserNeighborhood &&
            !neighborhoodOptions.find(neighborhood => neighborhood === currentUserNeighborhood)
          ) {
            updatedAddress.neighborhood = currentUserNeighborhood;

            neighborhoodOptions.unshift(currentUserNeighborhood);
            fetchedNeighborhoods.unshift(this.createOption(currentUserNeighborhood));

            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          } else if (currentUserNeighborhood) {
            selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
          }

          const updatedFormData = update(formDataWithUpdatedAddressesFields, {
            propertyAccess: { keysDevolutionAddress: { $set: updatedAddress } },
          });

          const updatedSchema = update(schemaWithUpdatedAddressesFields, {
            definitions: {
              propertyAccess: {
                properties: {
                  keysDevolutionAddress: {
                    $set: {
                      properties: {
                        ...schema.definitions.address.properties,
                        state: {
                          enum: fetchedStates?.map(option => option?.name) || [],
                          enumNames: fetchedStates?.map(option => option?.name) || [],
                        },
                        city: {
                          enum: fetchedCities?.map(option => option?.name) || [],
                          enumNames: fetchedCities?.map(option => option?.name) || [],
                        },
                        neighborhood: { enum: neighborhoodOptions, enumNames: neighborhoodOptions },
                      },
                    },
                  },
                },
              },
            },
          });

          formDataWithUpdatedAddressesFields = { ...updatedFormData };
          schemaWithUpdatedAddressesFields = { ...updatedSchema };

          this.setState({
            propertyAccessKeysDevolutionAddressCities: fetchedCities,
            propertyAccessKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessKeysDevolutionAddressSelectedState: selectedState,
            propertyAccessKeysDevolutionAddressSelectedCity: selectedCity,
            propertyAccessKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,
            formData: formDataWithUpdatedAddressesFields,
            schema: schemaWithUpdatedAddressesFields,
          });
        };

        // PropertyAccess keys devolution address zipcode
        if (
          formData?.propertyAccess?.keysDevolutionAddress?.zipcode &&
          this.keySelectionTypesToShowAddressFields.includes(formData?.propertyAccess?.keysDevolutionType)
        ) {
          await this.handleZipcodeFieldChange({
            zipcode: formData.propertyAccess.keysDevolutionAddress.zipcode,
            address: formData.propertyAccess.keysDevolutionAddress,
            handleSetAddressDataInState: handleSetPropertyKeysDevolutionAddressDataInState,
          });
        } else if (this.keySelectionTypesToShowAddressFields.includes(formData?.propertyAccess?.keysDevolutionType)) {
          await this.handleSetAddressByOrderData({
            address: formData?.propertyAccess?.keysDevolutionAddress,
            handleSetAddressDataInState: handleSetPropertyKeysDevolutionAddressDataInState,
          });
        }

        if (orderType === 'marketplace_fichacerta') {
          const handleSetDesiredPropertyAddressDataInState = ({
            address,
            fetchedCities,
            fetchedNeighborhoods,
            selectedState,
            selectedCity,
            selectedNeighborhood,
          }) => {
            const updatedAddress = {
              zipcode: formData.desired_property.address?.zipcode,
              street: formData.desired_property.address?.street || address?.street,
              neighborhood: formData.desired_property.address?.neighborhood || address?.neighborhood,
              city: formData.desired_property.address?.city || address?.city,
              state: formData.desired_property.address?.state || address?.state,
              number: formData.desired_property.address?.number,
              complement: formData.desired_property.address?.complement,
            };

            const currentUserNeighborhood = formData.desired_property.address?.neighborhood;
            const neighborhoodOptions = fetchedNeighborhoods?.map(option => option?.name) || [];

            if (
              currentUserNeighborhood &&
              !neighborhoodOptions.find(neighborhood => neighborhood === currentUserNeighborhood)
            ) {
              updatedAddress.neighborhood = currentUserNeighborhood;

              neighborhoodOptions.unshift(currentUserNeighborhood);
              fetchedNeighborhoods.unshift(this.createOption(currentUserNeighborhood));

              selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
            } else if (currentUserNeighborhood) {
              selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
            }

            const updatedFormData = update(formDataWithUpdatedAddressesFields, {
              desired_property: { address: { $set: updatedAddress } },
            });

            const updatedSchema = update(schemaWithUpdatedAddressesFields, {
              definitions: {
                desired_property_address: {
                  properties: {
                    state: {
                      enum: { $set: fetchedStates?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedStates?.map(option => option?.name) || [] },
                    },
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: { enum: { $set: neighborhoodOptions }, enumNames: { $set: neighborhoodOptions } },
                  },
                },
              },
            });

            formDataWithUpdatedAddressesFields = { ...updatedFormData };
            schemaWithUpdatedAddressesFields = { ...updatedSchema };

            this.setState({
              desiredPropertyAddressCities: fetchedCities,
              desiredPropertyAddressNeighborhoods: fetchedNeighborhoods,
              desiredPropertyAddressSelectedState: selectedState,
              desiredPropertyAddressSelectedCity: selectedCity,
              desiredPropertyAddressSelectedNeighborhood: selectedNeighborhood,
              formData: formDataWithUpdatedAddressesFields,
              schema: schemaWithUpdatedAddressesFields,
            });
          };

          // DesiredProperty address zipcode
          if (formData?.desired_property?.address?.zipcode) {
            await this.handleZipcodeFieldChange({
              zipcode: formData.desired_property.address.zipcode,
              address: formData.desired_property.address,
              handleSetAddressDataInState: handleSetDesiredPropertyAddressDataInState,
            });
          } else {
            await this.handleSetAddressByOrderData({
              address: formData?.desired_property?.address,
              handleSetAddressDataInState: handleSetDesiredPropertyAddressDataInState,
            });
          }

          const handleSetMainSuitorAddressDataInState = ({
            address,
            fetchedCities,
            fetchedNeighborhoods,
            selectedState,
            selectedCity,
            selectedNeighborhood,
          }) => {
            const updatedAddress = {
              zipcode: formData.main_suitor_address?.zipcode,
              street: formData.main_suitor_address?.street || address?.street,
              neighborhood: formData.main_suitor_address?.neighborhood || address?.neighborhood,
              city: formData.main_suitor_address?.city || address?.city,
              state: formData.main_suitor_address?.state || address?.state,
              number: formData.main_suitor_address?.number,
              complement: formData.main_suitor_address?.complement,
            };

            const currentUserNeighborhood = formData.main_suitor_address?.neighborhood;
            const neighborhoodOptions = fetchedNeighborhoods?.map(option => option?.name) || [];

            if (
              currentUserNeighborhood &&
              !neighborhoodOptions.find(neighborhood => neighborhood === currentUserNeighborhood)
            ) {
              updatedAddress.neighborhood = currentUserNeighborhood;

              neighborhoodOptions.unshift(currentUserNeighborhood);
              fetchedNeighborhoods.unshift(this.createOption(currentUserNeighborhood));

              selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
            } else if (currentUserNeighborhood) {
              selectedNeighborhood = { label: currentUserNeighborhood, value: currentUserNeighborhood };
            }

            const updatedFormData = update(formDataWithUpdatedAddressesFields, {
              main_suitor_address: { $set: updatedAddress },
            });

            const updatedSchema = update(schemaWithUpdatedAddressesFields, {
              definitions: {
                main_suitor_address: {
                  properties: {
                    state: {
                      enum: { $set: fetchedStates?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedStates?.map(option => option?.name) || [] },
                    },
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: { enum: { $set: neighborhoodOptions }, enumNames: { $set: neighborhoodOptions } },
                  },
                },
              },
            });

            formDataWithUpdatedAddressesFields = { ...updatedFormData };
            schemaWithUpdatedAddressesFields = { ...updatedSchema };

            this.setState({
              mainSuitorAddressCities: fetchedCities,
              mainSuitorAddressNeighborhoods: fetchedNeighborhoods,
              mainSuitorAddressSelectedState: selectedState,
              mainSuitorAddressSelectedCity: selectedCity,
              mainSuitorAddressSelectedNeighborhood: selectedNeighborhood,
              formData: formDataWithUpdatedAddressesFields,
              schema: schemaWithUpdatedAddressesFields,
            });
          };

          // Main address zipcode
          if (formData?.main_suitor_address?.zipcode) {
            await this.handleZipcodeFieldChange({
              zipcode: formData.main_suitor_address.zipcode,
              address: formData.main_suitor_address,
              handleSetAddressDataInState: handleSetMainSuitorAddressDataInState,
            });
          } else {
            await this.handleSetAddressByOrderData({
              address: formData?.main_suitor_address,
              handleSetAddressDataInState: handleSetMainSuitorAddressDataInState,
            });
          }
        }
      } catch (err) {
        console.debug('[DEBUG] handleZipcodeFieldChange with formData zipcode in componentDidMount', err);

        this.setAddressSelectedFields(formDataWithUpdatedAddressesFields);
      }
    } else if (
      isOrderEdition &&
      !formData.recipients &&
      formData.inspection_type !== 'visitaDigital' &&
      formData.inspection_type !== 'fotosDeCaptacao'
    ) {
      updatedOrderPriceData = await this.calculateOrderPrice(formData, uiSchema);
    }

    let clientAddresses = [];

    try {
      if (formData.clients?.client) {
        clientAddresses = await this.handleFetchRealEstateAddressesByClientId(formData.clients.client);
      }
    } catch {
      clientAddresses = [];
    }

    if (clientAddresses.length) {
      const handleRealEstateAddresses = ({ accessType, type, key, label, id }) => {
        if (formData[accessType]?.[type] === 'Na imobiliária' && formData[accessType]?.[key]) {
          uiSchema[accessType] = {
            ...uiSchema[accessType],
            [key]: {
              'ui:field': 'realEstateAdressesSelectField',
              'ui:options': clientAddresses,
              'ui:label': label,
              'ui:id': id,
            },
          };
        }
      };

      handleRealEstateAddresses({
        accessType: 'accessInformation',
        type: 'type',
        key: 'realEstateAddress',
        label: 'realEstateForKeysLocation',
        id: 'real-estate-address-select',
      });
      handleRealEstateAddresses({
        accessType: 'propertyAccess',
        type: 'type',
        key: 'realEstateAddress',
        label: 'realEstateForKeysLocation',
        id: 'real-estate-address-select',
      });
      handleRealEstateAddresses({
        accessType: 'accessInformation',
        type: 'keysDevolutionType',
        key: 'devolutionRealEstateAddress',
        label: 'devolutionRealEstateForKeysLocation',
        id: 'devolutiion-real-estate-address-select',
      });
      handleRealEstateAddresses({
        accessType: 'propertyAccess',
        type: 'keysDevolutionType',
        key: 'devolutionRealEstateAddress',
        label: 'devolutionRealEstateForKeysLocation',
        id: 'devolutiion-real-estate-address-select',
      });
    }

    const initialState = {
      loading: false,
      isOrderEdition,
      uiSchema,
      schema: schemaWithUpdatedAddressesFields,
      formData: formDataWithUpdatedAddressesFields,
      addressesStates: fetchedStates,
      realEstateAddresses: clientAddresses,
      ...(updatedOrderPriceData || {}),
    };

    if (initialState.schema?.definitions?.address) {
      initialState.schema = update(initialState.schema, {
        definitions: {
          address: {
            properties: {
              state: {
                enum: { $set: fetchedStates?.map(option => option?.name) || [] },
                enumNames: { $set: fetchedStates?.map(option => option?.name) || [] },
              },
            },
          },
        },
      });
    }

    if (initialState.schema?.definitions?.desired_property_address) {
      initialState.schema = update(initialState.schema, {
        definitions: {
          desired_property_address: {
            properties: {
              state: {
                enum: { $set: fetchedStates?.map(option => option?.name) || [] },
                enumNames: { $set: fetchedStates?.map(option => option?.name) || [] },
              },
            },
          },
        },
      });
    }

    if (initialState.schema?.definitions?.main_suitor_address) {
      initialState.schema = update(initialState.schema, {
        definitions: {
          main_suitor_address: {
            properties: {
              state: {
                enum: { $set: fetchedStates?.map(option => option?.name) || [] },
                enumNames: { $set: fetchedStates?.map(option => option?.name) || [] },
              },
            },
          },
        },
      });
    }

    this.setState(initialState);
  }

  async componentDidUpdate(_, prevState) {
    const { realEstateAddresses } = this.state;

    const updateAddressFieldsBasedOnRealEstateAddress = async ({ prop, addressType, addressKey, realEstateKey }) => {
      if (!!this.state[prop] && prevState[prop] !== this.state[prop] && realEstateAddresses.length > 0) {
        const uniqueRealEstate = realEstateAddresses[0];
        const { address: uniqueAddress, public_id: realEstateId } = uniqueRealEstate;

        const addressStatePrefix = addressType + addressKey.charAt(0).toUpperCase() + addressKey.slice(1);

        try {
          await this.handleZipcodeFieldChange({
            address: uniqueAddress,
            zipcode: uniqueAddress?.zipcode,
            handleSetAddressDataInState: ({
              address,
              fetchedCities,
              fetchedNeighborhoods,
              selectedState,
              selectedCity,
              selectedNeighborhood,
            }) => {
              const updatedAddress = {
                zipcode: uniqueAddress.zipcode,
                street: address.street,
                neighborhood: address.neighborhood,
                city: address.city,
                state: address.state,
                number: uniqueAddress.number,
                complement: uniqueAddress.complement,
              };

              this.setState(state => ({
                [`${addressStatePrefix}Cities`]: fetchedCities,
                [`${addressStatePrefix}Neighborhoods`]: fetchedNeighborhoods,
                [`${addressStatePrefix}SelectedState`]: selectedState,
                [`${addressStatePrefix}SelectedCity`]: selectedCity,
                [`${addressStatePrefix}SelectedNeighborhood`]: selectedNeighborhood,

                formData: {
                  ...state.formData,
                  [addressType]: {
                    ...state.formData[addressType],
                    [addressKey]: updatedAddress,
                    [realEstateKey]: realEstateId,
                  },
                },

                schema: update(state.schema, {
                  definitions: {
                    [addressType]: {
                      properties: {
                        [addressKey]: {
                          $set: {
                            properties: {
                              ...state.schema.definitions.address.properties,
                              city: {
                                enum: fetchedCities?.map(option => option?.name) || [],
                                enumNames: fetchedCities?.map(option => option?.name) || [],
                              },
                              neighborhood: {
                                enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                                enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                }),
              }));
            },
          });
        } catch (err) {
          console.debug('[DEBUG] handleZipcodeFieldChange error on updateAddressFieldsBasedOnRealEstateAddress', err);
        }
      }
    };

    await updateAddressFieldsBasedOnRealEstateAddress({
      prop: 'changeAccessInformationAddressFieldsAutomatically',
      addressType: 'accessInformation',
      addressKey: 'address',
      realEstateKey: 'realEstateAddress',
    });
    await updateAddressFieldsBasedOnRealEstateAddress({
      prop: 'changePropertyAccessAddressFieldsAutomatically',
      addressType: 'propertyAccess',
      addressKey: 'address',
      realEstateKey: 'realEstateAddress',
    });
    await updateAddressFieldsBasedOnRealEstateAddress({
      prop: 'changeAccessInformationkeysDevolutionAddressFieldsAutomatically',
      addressType: 'accessInformation',
      addressKey: 'keysDevolutionAddress',
      realEstateKey: 'devolutionRealEstateAddress',
    });
    await updateAddressFieldsBasedOnRealEstateAddress({
      prop: 'changePropertyAccesskeysDevolutionAddressFieldsAutomatically',
      addressType: 'propertyAccess',
      addressKey: 'keysDevolutionAddress',
      realEstateKey: 'devolutionRealEstateAddress',
    });
  }

  handleTagChange = ({ formData }) => this.setState(state => ({ formData: { ...state.formData, tags: formData } }));

  handleFetchRealEstateAddressesByClientId = async clientId => {
    if (!clientId) return [];

    try {
      const { data } = await API.get(`/clients/${clientId}/addresses`);

      return Array.isArray(data?.data) ? data.data : [];
    } catch {
      return [];
    }
  };

  handleChange = async ({ errors, formData }) => {
    const { formData: formDataState, priceCalculated } = this.state;
    const { handleError } = this.props;

    let calculatePrice = false;
    let addressFieldsToEmpty = {};

    // Control the data that change the price
    if (
      (formData?.type !== 'docusign' &&
        formData?.type !== 'fichacerta' &&
        formData?.type !== 'visitaDigital' &&
        formData?.clients?.client &&
        formData?.details?.area &&
        formData?.details?.building_type &&
        formData?.details?.furnished &&
        ((formData?.inspection_type !== 'tourVirtual' &&
          formData?.type !== 'acompanhamentoObraMM' &&
          formData?.additionals?.modality &&
          formData?.additionals?.urgency) ||
          formData?.inspection_type === 'tourVirtual' ||
          formData?.inspection_type === 'acompanhamentoObraMM')) ||
      (formData?.inspection_type === 'fotosDeCaptacao' && formData?.clients?.client && formData?.details?.building_type)
    ) {
      if (priceCalculated) {
        if (
          formData?.clients?.client !== formDataState?.clients?.client ||
          formData?.details?.area !== formDataState?.details?.area ||
          formData?.details?.building_type !== formDataState?.details?.building_type ||
          formData?.details?.furnished !== formDataState?.details?.furnished ||
          formData?.additionals?.modality !== formDataState?.additionals?.modality ||
          formData?.additionals?.urgency !== formDataState?.additionals?.urgency
        ) {
          calculatePrice = true;
        }
      } else {
        calculatePrice = true;
        this.setState({ priceCalculated: true });
      }
    }

    // If selected budgetDuringInspection.includeBudget option is "simple" or "termination'
    if (
      !!formData?.budgetDuringInspection?.includeBudget &&
      formData.budgetDuringInspection.includeBudget !== 'no' &&
      formData.budgetDuringInspection.includeBudget !== formDataState?.budgetDuringInspection?.includeBudget
    ) {
      calculatePrice = true;
    }

    // If selected budgetDuringInspection.includeBudget option is "no"
    if (
      formData?.budgetDuringInspection?.includeBudget === 'no' &&
      !!formDataState?.budgetDuringInspection?.includeBudget &&
      formData.budgetDuringInspection.includeBudget !== formDataState.budgetDuringInspection.includeBudget
    ) {
      calculatePrice = true;
    }

    if (
      formData.accessInformation?.type !== 'Na imobiliária' &&
      formData.accessInformation?.type !== 'Outro' &&
      formData.accessInformation?.type !== formDataState.accessInformation?.type &&
      !!this.state.schema.definitions?.accessInformation?.properties?.address
    ) {
      this.setState(state => ({
        accessInformationAddressCities: [],
        accessInformationAddressNeighborhoods: [],
        accessInformationAddressSelectedState: null,
        accessInformationAddressSelectedCity: null,
        accessInformationAddressSelectedNeighborhood: null,

        schema: update(state.schema, {
          definitions: {
            accessInformation: { properties: { $unset: ['address'] } },
          },
        }),
      }));
    }

    if (
      formData.accessInformation?.keysDevolutionType !== 'Na imobiliária' &&
      formData.accessInformation?.keysDevolutionType !== 'Outro' &&
      formData.accessInformation?.keysDevolutionType !== formDataState.accessInformation?.keysDevolutionType &&
      !!this.state.schema.definitions?.accessInformation?.properties?.keysDevolutionAddress
    ) {
      this.setState(state => ({
        accessInformationKeysDevolutionAddressCities: [],
        accessInformationKeysDevolutionAddressNeighborhoods: [],
        accessInformationKeysDevolutionAddressSelectedState: null,
        accessInformationKeysDevolutionAddressSelectedCity: null,
        accessInformationKeysDevolutionAddressSelectedNeighborhood: null,

        schema: update(state.schema, {
          definitions: {
            accessInformation: { properties: { $unset: ['keysDevolutionAddress'] } },
          },
        }),
      }));
    }

    if (
      formData.propertyAccess?.type !== 'Na imobiliária' &&
      formData.propertyAccess?.type !== 'Outro' &&
      formData.propertyAccess?.type !== formDataState.propertyAccess?.type &&
      !!this.state.schema.definitions?.propertyAccess?.properties?.address
    ) {
      this.setState(state => ({
        propertyAccessAddressCities: [],
        propertyAccessAddressNeighborhoods: [],
        propertyAccessAddressSelectedState: null,
        propertyAccessAddressSelectedCity: null,
        propertyAccessAddressSelectedNeighborhood: null,

        schema: update(state.schema, {
          definitions: {
            propertyAccess: { properties: { $unset: ['address'] } },
          },
        }),
      }));
    }

    if (
      formData.propertyAccess?.keysDevolutionType !== 'Na imobiliária' &&
      formData.propertyAccess?.keysDevolutionType !== 'Outro' &&
      formData.propertyAccess?.keysDevolutionType !== formDataState.propertyAccess?.keysDevolutionType &&
      !!this.state.schema.definitions?.propertyAccess?.properties?.keysDevolutionAddress
    ) {
      this.setState(state => ({
        propertyAccessKeysDevolutionAddressCities: [],
        propertyAccessKeysDevolutionAddressNeighborhoods: [],
        propertyAccessKeysDevolutionAddressSelectedState: null,
        propertyAccessKeysDevolutionAddressSelectedCity: null,
        propertyAccessKeysDevolutionAddressSelectedNeighborhood: null,

        schema: update(state.schema, {
          definitions: {
            propertyAccess: { properties: { $unset: ['keysDevolutionAddresss'] } },
          },
        }),
      }));
    }

    // States fields changes
    if (formData.address?.state && formData.address.state !== formDataState.address?.state) {
      await this.handleStateFieldChange({
        stateName: formData.address.state,
        handleSetSelectedState: selectedState => this.setState({ addressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            addressCities: fetchedCities,
            addressNeighborhoods: [],
            addressSelectedCity: null,
            addressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: [] },
                      enumNames: { $set: [] },
                    },
                  },
                },
              },
            }),
          }));

          addressFieldsToEmpty = { address: { ...formData?.address, city: null } };
        },
      });
    }

    if (
      formData.accessInformation?.address?.state &&
      formData.accessInformation.address.state !== formDataState.accessInformation?.address?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.accessInformation.address.state,
        handleSetSelectedState: selectedState =>
          this.setState({ accessInformationAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            accessInformationAddressCities: fetchedCities,
            accessInformationAddressNeighborhoods: [],
            accessInformationAddressSelectedCity: null,
            accessInformationAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                accessInformation: {
                  properties: {
                    address: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: [],
                            enumNames: [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressFieldsToEmpty = {
            accessInformation: {
              ...formData?.accessInformation,
              address: { ...formData?.accessInformation?.address, city: null },
            },
          };
        },
      });
    }

    if (
      formData.accessInformation?.keysDevolutionAddress?.state &&
      formData.accessInformation.keysDevolutionAddress.state !==
        formDataState.accessInformation?.keysDevolutionAddress?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.accessInformation.keysDevolutionAddress.state,
        handleSetSelectedState: selectedState =>
          this.setState({ accessInformationKeysDevolutionAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            accessInformationKeysDevolutionAddressCities: fetchedCities,
            accessInformationKeysDevolutionAddressNeighborhoods: [],
            accessInformationKeysDevolutionAddressSelectedCity: null,
            accessInformationKeysDevolutionAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                accessInformation: {
                  properties: {
                    keysDevolutionAddress: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: [],
                            enumNames: [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressFieldsToEmpty = {
            accessInformation: {
              ...formData?.accessInformation,
              keysDevolutionAddress: { ...formData?.accessInformation?.keysDevolutionAddress, city: null },
            },
          };
        },
      });
    }

    if (
      formData.propertyAccess?.address?.state &&
      formData.propertyAccess.address.state !== formDataState.propertyAccess?.address?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.propertyAccess.address.state,
        handleSetSelectedState: selectedState => this.setState({ propertyAccessAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            propertyAccessAddressCities: fetchedCities,
            propertyAccessAddressNeighborhoods: [],
            propertyAccessAddressSelectedCity: null,
            propertyAccessAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    address: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: [],
                            enumNames: [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressFieldsToEmpty = {
            propertyAccess: {
              ...formData?.propertyAccess,
              address: { ...formData?.propertyAccess?.address, city: null },
            },
          };
        },
      });
    }

    if (
      formData.propertyAccess?.keysDevolutionAddress?.state &&
      formData.propertyAccess.keysDevolutionAddress.state !== formDataState.propertyAccess?.keysDevolutionAddress?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.propertyAccess.keysDevolutionAddress.state,
        handleSetSelectedState: selectedState =>
          this.setState({ propertyAccessKeysDevolutionAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            propertyAccessKeysDevolutionAddressCities: fetchedCities,
            propertyAccessKeysDevolutionAddressNeighborhoods: [],
            propertyAccessKeysDevolutionAddressSelectedCity: null,
            propertyAccessKeysDevolutionAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    keysDevolutionAddress: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: [],
                            enumNames: [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressFieldsToEmpty = {
            propertyAccess: {
              ...formData?.propertyAccess,
              keysDevolutionAddress: { ...formData?.propertyAccess?.keysDevolutionAddress, city: null },
            },
          };
        },
      });
    }

    if (
      formData?.desired_property?.address?.state &&
      formData.desired_property.address.state !== formDataState?.desired_property?.address?.state
    ) {
      await this.handleStateFieldChange({
        stateName: formData.desired_property.address.state,
        handleSetSelectedState: selectedState => this.setState({ desiredPropertyAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            desiredPropertyAddressCities: fetchedCities,
            desiredPropertyAddressNeighborhoods: [],
            desiredPropertyAddressSelectedCity: null,
            desiredPropertyAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                desired_property_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: [] },
                      enumNames: { $set: [] },
                    },
                  },
                },
              },
            }),
          }));

          addressFieldsToEmpty = {
            desired_property: {
              ...formData.desired_property,
              address: { ...formData.desired_property.address, city: null },
            },
          };
        },
      });
    }

    if (formData?.main_suitor_address?.state && formData.main_suitor_address.state !== formDataState?.main_suitor_address?.state) {
      await this.handleStateFieldChange({
        stateName: formData.main_suitor_address.state,
        handleSetSelectedState: selectedState => this.setState({ mainSuitorAddressSelectedState: selectedState }),
        handleSetCitiesInState: fetchedCities => {
          this.setState(state => ({
            mainSuitorAddressCities: fetchedCities,
            mainSuitorAddressNeighborhoods: [],
            mainSuitorAddressSelectedCity: null,
            mainSuitorAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                main_suitor_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: [] },
                      enumNames: { $set: [] },
                    },
                  },
                },
              },
            }),
          }));

          addressFieldsToEmpty = { main_suitor_address: { ...formData.main_suitor_address, city: null } };
        },
      });
    }

    // Cities fields changes
    if (formData.address?.city && formData.address.city !== formDataState.address?.city) {
      await this.handleCityFieldChange({
        cityName: formData.address.city,
        citiesInState: this.state.addressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            addressNeighborhoods: fetchedNeighborhoods,
            addressSelectedCity: selectedCity,
            addressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                address: {
                  properties: {
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    if (
      formData.accessInformation?.address?.city &&
      formData.accessInformation.address.city !== formDataState.accessInformation?.address?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.accessInformation.address.city,
        citiesInState: this.state.accessInformationAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            accessInformationAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationAddressSelectedCity: selectedCity,
            accessInformationAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                accessInformation: {
                  properties: {
                    address: {
                      properties: {
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    if (
      formData.accessInformation?.keysDevolutionAddress?.city &&
      formData.accessInformation.keysDevolutionAddress.city !==
        formDataState.accessInformation?.keysDevolutionAddress?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.accessInformation.keysDevolutionAddress.city,
        citiesInState: this.state.accessInformationKeysDevolutionAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            accessInformationKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationKeysDevolutionAddressSelectedCity: selectedCity,
            accessInformationKeysDevolutionAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                accessInformation: {
                  properties: {
                    keysDevolutionAddress: {
                      properties: {
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    if (
      formData.propertyAccess?.address?.city &&
      formData.propertyAccess.address.city !== formDataState.propertyAccess?.address?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.propertyAccess.address.city,
        citiesInState: this.state.propertyAccessAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            propertyAccessAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessAddressSelectedCity: selectedCity,
            propertyAccessAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    address: {
                      properties: {
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    if (
      formData.propertyAccess?.keysDevolutionAddress?.city &&
      formData.propertyAccess.keysDevolutionAddress.city !== formDataState.propertyAccess?.keysDevolutionAddress?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.propertyAccess.keysDevolutionAddress.city,
        citiesInState: this.state.propertyAccessKeysDevolutionAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            propertyAccessKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessKeysDevolutionAddressSelectedCity: selectedCity,
            propertyAccessKeysDevolutionAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    keysDevolutionAddress: {
                      properties: {
                        neighborhood: {
                          enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                          enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    if (
      formData?.desired_property?.address?.city &&
      formData.desired_property.address.city !== formDataState?.desired_property?.address?.city
    ) {
      await this.handleCityFieldChange({
        cityName: formData.desired_property.address.city,
        citiesInState: this.state.desiredPropertyAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            desiredPropertyAddressNeighborhoods: fetchedNeighborhoods,
            desiredPropertyAddressSelectedCity: selectedCity,
            desiredPropertyAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                desired_property_address: {
                  properties: {
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    if (formData?.main_suitor_address?.city && formData.main_suitor_address.city !== formDataState?.main_suitor_address?.city) {
      await this.handleCityFieldChange({
        cityName: formData.main_suitor_address.city,
        citiesInState: this.state.mainSuitorAddressCities,
        handleSetNeighborhoodsInState: ({ fetchedNeighborhoods, selectedCity }) => {
          this.setState(state => ({
            mainSuitorAddressNeighborhoods: fetchedNeighborhoods,
            mainSuitorAddressSelectedCity: selectedCity,
            mainSuitorAddressSelectedNeighborhood: null,

            schema: update(state.schema, {
              definitions: {
                main_suitor_address: {
                  properties: {
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));
        },
      });
    }

    // Neighborhoods fields changes
    if (formData.address?.neighborhood && formData.address.neighborhood !== formDataState.address?.neighborhood) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.address.neighborhood,
        neighborhoodsInState: this.state.addressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ addressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    if (
      formData.accessInformation?.address?.neighborhood &&
      formData.accessInformation.address.neighborhood !== formDataState.accessInformation?.address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.accessInformation.address.neighborhood,
        neighborhoodsInState: this.state.accessInformationAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ accessInformationAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    if (
      formData.accessInformation?.keysDevolutionAddress?.neighborhood &&
      formData.accessInformation.keysDevolutionAddress.neighborhood !==
        formDataState.accessInformation?.keysDevolutionAddress?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.accessInformation.keysDevolutionAddress.neighborhood,
        neighborhoodsInState: this.state.accessInformationKeysDevolutionAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ accessInformationKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    if (
      formData.propertyAccess?.address?.neighborhood &&
      formData.propertyAccess.address.neighborhood !== formDataState.propertyAccess?.address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.propertyAccess.address.neighborhood,
        neighborhoodsInState: this.state.propertyAccessAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ propertyAccessAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    if (
      formData.propertyAccess?.keysDevolutionAddress?.neighborhood &&
      formData.propertyAccess.keysDevolutionAddress.neighborhood !==
        formDataState.propertyAccess?.keysDevolutionAddress?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.propertyAccess.keysDevolutionAddress.neighborhood,
        neighborhoodsInState: this.state.propertyAccessKeysDevolutionAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ propertyAccessKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    if (
      formData?.desired_property?.address?.neighborhood &&
      formData.desired_property.address.neighborhood !== formDataState?.desired_property?.address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.desired_property.address.neighborhood,
        neighborhoodsInState: this.state.desiredPropertyAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ desiredPropertyAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    if (
      formData?.main_suitor_address?.neighborhood &&
      formData.main_suitor_address.neighborhood !== formDataState?.main_suitor_address?.neighborhood
    ) {
      this.handleNeighborhoodFieldChange({
        neighborhoodName: formData.main_suitor_address.neighborhood,
        neighborhoodsInState: this.state.mainSuitorAddressNeighborhoods,
        handleSetSelectedNeighborhoodInState: selectedNeighborhood => {
          this.setState({ mainSuitorAddressSelectedNeighborhood: selectedNeighborhood });
        },
      });
    }

    let addressesFieldsChangedByZipcodeRequest = false;

    // Zipcode fields changes
    if (
      formData.address?.zipcode &&
      formData.address.zipcode !== formDataState.address?.zipcode &&
      this.addressService.validateZipcode(formData.address.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData?.address?.zipcode,
            street: address?.street,
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            number: formData?.address?.number,
            complement: formData?.address?.complement,
          };

          this.setState(state => ({
            addressCities: fetchedCities,
            addressNeighborhoods: fetchedNeighborhoods,
            addressSelectedState: selectedState,
            addressSelectedCity: selectedCity,
            addressSelectedNeighborhood: selectedNeighborhood,

            formData: update(state.formData, { address: { $set: updatedAddress } }),

            schema: update(state.schema, {
              definitions: {
                address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsChangedByZipcodeRequest = true;
        },
      });
    }

    if (
      formData.accessInformation?.address?.zipcode &&
      formData.accessInformation.address.zipcode !== formDataState.accessInformation?.address?.zipcode &&
      this.addressService.validateZipcode(formData.accessInformation.address.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.accessInformation.address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData?.accessInformation?.address?.zipcode,
            street: address?.street,
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            number: formData?.accessInformation?.address?.number,
            complement: formData?.accessInformation?.address?.complement,
          };

          this.setState(state => ({
            accessInformationAddressCities: fetchedCities,
            accessInformationAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationAddressSelectedState: selectedState,
            accessInformationAddressSelectedCity: selectedCity,
            accessInformationAddressSelectedNeighborhood: selectedNeighborhood,

            formData: update(state.formData, { accessInformation: { address: { $set: updatedAddress } } }),

            schema: update(state.schema, {
              definitions: {
                accessInformation: {
                  properties: {
                    address: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                            enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsChangedByZipcodeRequest = true;
        },
      });
    }

    if (
      formData.accessInformation?.keysDevolutionAddress?.zipcode &&
      formData.accessInformation.keysDevolutionAddress.zipcode !==
        formDataState.accessInformation?.keysDevolutionAddress?.zipcode &&
      this.addressService.validateZipcode(formData.accessInformation.keysDevolutionAddress.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.accessInformation.keysDevolutionAddress.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData?.accessInformation?.keysDevolutionAddress?.zipcode,
            street: address?.street,
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            number: formData?.accessInformation?.keysDevolutionAddress?.number,
            complement: formData?.accessInformation?.keysDevolutionAddress?.complement,
          };

          this.setState(state => ({
            accessInformationKeysDevolutionAddressCities: fetchedCities,
            accessInformationKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            accessInformationKeysDevolutionAddressSelectedState: selectedState,
            accessInformationKeysDevolutionAddressSelectedCity: selectedCity,
            accessInformationKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,

            formData: update(state.formData, {
              accessInformation: { keysDevolutionAddress: { $set: updatedAddress } },
            }),

            schema: update(state.schema, {
              definitions: {
                accessInformation: {
                  properties: {
                    keysDevolutionAddress: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                            enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsChangedByZipcodeRequest = true;
        },
      });
    }

    if (
      formData.propertyAccess?.address?.zipcode &&
      formData.propertyAccess.address.zipcode !== formDataState.propertyAccess?.address?.zipcode &&
      this.addressService.validateZipcode(formData.propertyAccess.address.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.propertyAccess.address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData?.propertyAccess?.address?.zipcode,
            street: address?.street,
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            number: formData?.propertyAccess?.address?.number,
            complement: formData?.propertyAccess?.address?.complement,
          };

          this.setState(state => ({
            propertyAccessAddressCities: fetchedCities,
            propertyAccessAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessAddressSelectedState: selectedState,
            propertyAccessAddressSelectedCity: selectedCity,
            propertyAccessAddressSelectedNeighborhood: selectedNeighborhood,

            formData: update(state.formData, { propertyAccess: { address: { $set: updatedAddress } } }),

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    address: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                            enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsChangedByZipcodeRequest = true;
        },
      });
    }

    if (
      formData.propertyAccess?.keysDevolutionAddress?.zipcode &&
      formData.propertyAccess.keysDevolutionAddress.zipcode !==
        formDataState.propertyAccess?.keysDevolutionAddress?.zipcode &&
      this.addressService.validateZipcode(formData.propertyAccess.keysDevolutionAddress.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.propertyAccess.keysDevolutionAddress.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData?.propertyAccess?.keysDevolutionAddress?.zipcode,
            street: address?.street,
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            number: formData?.propertyAccess?.keysDevolutionAddress?.number,
            complement: formData?.propertyAccess?.keysDevolutionAddress?.complement,
          };

          this.setState(state => ({
            propertyAccessKeysDevolutionAddressCities: fetchedCities,
            propertyAccessKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
            propertyAccessKeysDevolutionAddressSelectedState: selectedState,
            propertyAccessKeysDevolutionAddressSelectedCity: selectedCity,
            propertyAccessKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,

            formData: update(state.formData, { propertyAccess: { keysDevolutionAddress: { $set: updatedAddress } } }),

            schema: update(state.schema, {
              definitions: {
                propertyAccess: {
                  properties: {
                    keysDevolutionAddress: {
                      $set: {
                        properties: {
                          ...state.schema.definitions.address.properties,
                          city: {
                            enum: fetchedCities?.map(option => option?.name) || [],
                            enumNames: fetchedCities?.map(option => option?.name) || [],
                          },
                          neighborhood: {
                            enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                            enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                          },
                        },
                      },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsChangedByZipcodeRequest = true;
        },
      });
    }

    if (
      formData?.desired_property?.address?.zipcode &&
      formData.desired_property.address.zipcode !== formDataState?.desired_property?.address?.zipcode &&
      this.addressService.validateZipcode(formData.desired_property.address.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.desired_property.address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.desired_property.address.zipcode,
            street: address?.street,
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            number: formData.desired_property.address?.number,
            complement: formData.desired_property.address?.complement,
          };

          this.setState(state => ({
            desiredPropertyAddressCities: fetchedCities,
            desiredPropertyAddressNeighborhoods: fetchedNeighborhoods,
            desiredPropertyAddressSelectedState: selectedState,
            desiredPropertyAddressSelectedCity: selectedCity,
            desiredPropertyAddressSelectedNeighborhood: selectedNeighborhood,

            formData: update(state.formData, { desired_property: { address: { $set: updatedAddress } } }),

            schema: update(state.schema, {
              definitions: {
                desired_property_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsChangedByZipcodeRequest = true;
        },
      });
    }

    if (
      formData?.main_suitor_address?.zipcode &&
      formData.main_suitor_address.zipcode !== formDataState?.main_suitor_address?.zipcode &&
      this.addressService.validateZipcode(formData.main_suitor_address.zipcode)
    ) {
      await this.handleZipcodeFieldChange({
        zipcode: formData.main_suitor_address.zipcode,
        handleSetAddressDataInState: ({
          address,
          fetchedCities,
          fetchedNeighborhoods,
          selectedState,
          selectedCity,
          selectedNeighborhood,
        }) => {
          const updatedAddress = {
            zipcode: formData.main_suitor_address.zipcode,
            street: address?.street,
            neighborhood: address?.neighborhood,
            city: address?.city,
            state: address?.state,
            number: formData.main_suitor_address?.number,
            complement: formData.main_suitor_address?.complement,
          };

          this.setState(state => ({
            mainSuitorAddressCities: fetchedCities,
            mainSuitorAddressNeighborhoods: fetchedNeighborhoods,
            mainSuitorAddressSelectedState: selectedState,
            mainSuitorAddressSelectedCity: selectedCity,
            mainSuitorAddressSelectedNeighborhood: selectedNeighborhood,

            formData: update(state.formData, { main_suitor_address: { $set: updatedAddress } }),

            schema: update(state.schema, {
              definitions: {
                main_suitor_address: {
                  properties: {
                    city: {
                      enum: { $set: fetchedCities?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedCities?.map(option => option?.name) || [] },
                    },
                    neighborhood: {
                      enum: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                      enumNames: { $set: fetchedNeighborhoods?.map(option => option?.name) || [] },
                    },
                  },
                },
              },
            }),
          }));

          addressesFieldsChangedByZipcodeRequest = true;
        },
      });
    }

    const handleFillAddressesSchemaFieldsWithClientAddresses = async ({
      accessType,
      type,
      fieldsAutomatically,
      key,
      label,
      id,
    }) => {
      if (formData[accessType]?.[type] !== formDataState[accessType]?.[type]) {
        if (formData[accessType]?.[type] === 'Na imobiliária') {
          let clientAddresses = [];

          try {
            if (formData.clients?.client) {
              clientAddresses = await this.handleFetchRealEstateAddressesByClientId(formData.clients.client);
            }
          } catch {
            clientAddresses = [];
          }

          this.setState(state => ({
            realEstateAddresses: clientAddresses,
            [fieldsAutomatically]: clientAddresses.length > 0,
            uiSchema: {
              ...state.uiSchema,
              [accessType]: {
                ...state.uiSchema[accessType],
                [key]: {
                  'ui:field': 'realEstateAdressesSelectField',
                  'ui:options': clientAddresses,
                  'ui:label': label,
                  'ui:id': id,
                },
              },
            },
          }));
        } else {
          this.setState(state => ({
            realEstateAddresses: [],
            [fieldsAutomatically]: false,
            uiSchema: {
              ...state.uiSchema,
              [accessType]: {
                ...state.uiSchema[accessType],
                [key]: { 'ui:widget': 'hidden', 'ui:hidden': true },
              },
            },
          }));

          !!formData[accessType]?.[key] && delete formData[accessType][key];
        }
      }
    };

    await Promise.all([
      handleFillAddressesSchemaFieldsWithClientAddresses({
        accessType: 'accessInformation',
        type: 'type',
        fieldsAutomatically: 'changeAccessInformationAddressFieldsAutomatically',
        key: 'realEstateAddress',
        label: 'realEstateForKeysLocation',
        id: 'real-estate-address-select',
      }),
      handleFillAddressesSchemaFieldsWithClientAddresses({
        accessType: 'propertyAccess',
        type: 'type',
        fieldsAutomatically: 'changePropertyAccessAddressFieldsAutomatically',
        key: 'realEstateAddress',
        label: 'realEstateForKeysLocation',
        id: 'real-estate-address-select',
      }),
      handleFillAddressesSchemaFieldsWithClientAddresses({
        accessType: 'accessInformation',
        type: 'keysDevolutionType',
        fieldsAutomatically: 'changeAccessInformationkeysDevolutionAddressFieldsAutomatically',
        key: 'devolutionRealEstateAddress',
        label: 'devolutionRealEstateForKeysLocation',
        id: 'devolutiion-real-estate-address-select',
      }),
      handleFillAddressesSchemaFieldsWithClientAddresses({
        accessType: 'propertyAccess',
        type: 'keysDevolutionType',
        fieldsAutomatically: 'changePropertyAccesskeysDevolutionAddressFieldsAutomatically',
        key: 'devolutionRealEstateAddress',
        label: 'devolutionRealEstateForKeysLocation',
        id: 'devolutiion-real-estate-address-select',
      }),
    ]);

    let isAccessInformationRealEstateAddressChangedByUserSelection = false;

    if (
      formData?.accessInformation?.realEstateAddress &&
      formData.accessInformation.realEstateAddress !== formDataState?.accessInformation?.realEstateAddress &&
      formDataState?.accessInformation?.type === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.accessInformation.realEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        await this.handleZipcodeFieldChange({
          address: selectedAddress,
          handleSetAddressDataInState: ({
            address,
            fetchedCities,
            fetchedNeighborhoods,
            selectedState,
            selectedCity,
            selectedNeighborhood,
          }) => {
            const updatedAddress = {
              zipcode: selectedAddress.zipcode,
              street: address.street,
              neighborhood: address.neighborhood,
              city: address.city,
              state: address.state,
              number: selectedAddress.number,
              complement: selectedAddress.complement,
            };

            this.setState(state => ({
              accessInformationAddressCities: fetchedCities,
              accessInformationAddressNeighborhoods: fetchedNeighborhoods,
              accessInformationAddressSelectedState: selectedState,
              accessInformationAddressSelectedCity: selectedCity,
              accessInformationAddressSelectedNeighborhood: selectedNeighborhood,

              formData: update(state.formData, {
                accessInformation: {
                  address: { $set: updatedAddress },
                  realEstateAddress: { $set: realEstateId },
                },
              }),

              schema: update(state.schema, {
                definitions: {
                  accessInformation: {
                    properties: {
                      address: {
                        $set: {
                          properties: {
                            ...state.schema.definitions.address.properties,
                            city: {
                              enum: fetchedCities?.map(option => option?.name) || [],
                              enumNames: fetchedCities?.map(option => option?.name) || [],
                            },
                            neighborhood: {
                              enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                              enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                            },
                          },
                        },
                      },
                    },
                  },
                },
              }),
            }));

            isAccessInformationRealEstateAddressChangedByUserSelection = true;
          },
        });
      }
    }

    if (
      !!formData.accessInformation?.devolutionRealEstateAddress &&
      formData.accessInformation.devolutionRealEstateAddress !==
        formDataState.accessInformation?.devolutionRealEstateAddress &&
      formDataState.accessInformation?.keysDevolutionType === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.accessInformation.devolutionRealEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        await this.handleZipcodeFieldChange({
          address: selectedAddress,
          handleSetAddressDataInState: ({
            address,
            fetchedCities,
            fetchedNeighborhoods,
            selectedState,
            selectedCity,
            selectedNeighborhood,
          }) => {
            const updatedAddress = {
              zipcode: selectedAddress.zipcode,
              street: address.street,
              neighborhood: address.neighborhood,
              city: address.city,
              state: address.state,
              number: selectedAddress.number,
              complement: selectedAddress.complement,
            };

            this.setState(state => ({
              accessInformationKeysDevolutionAddressCities: fetchedCities,
              accessInformationKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
              accessInformationKeysDevolutionAddressSelectedState: selectedState,
              accessInformationKeysDevolutionAddressSelectedCity: selectedCity,
              accessInformationKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,

              formData: update(state.formData, {
                accessInformation: {
                  keysDevolutionAddress: { $set: updatedAddress },
                  devolutionRealEstateAddress: { $set: realEstateId },
                },
              }),

              schema: update(state.schema, {
                definitions: {
                  accessInformation: {
                    properties: {
                      keysDevolutionAddress: {
                        $set: {
                          properties: {
                            ...state.schema.definitions.address.properties,
                            city: {
                              enum: fetchedCities?.map(option => option?.name) || [],
                              enumNames: fetchedCities?.map(option => option?.name) || [],
                            },
                            neighborhood: {
                              enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                              enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                            },
                          },
                        },
                      },
                    },
                  },
                },
              }),
            }));

            isAccessInformationRealEstateAddressChangedByUserSelection = true;
          },
        });
      }
    }

    let isPropertyAccessRealEstateAddressChangedByUserSelection = false;

    if (
      formData.propertyAccess?.realEstateAddress &&
      formData.propertyAccess.realEstateAddress !== formDataState.propertyAccess?.realEstateAddress &&
      formDataState.propertyAccess?.type === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.propertyAccess.realEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        await this.handleZipcodeFieldChange({
          address: selectedAddress,
          handleSetAddressDataInState: ({
            address,
            fetchedCities,
            fetchedNeighborhoods,
            selectedState,
            selectedCity,
            selectedNeighborhood,
          }) => {
            const updatedAddress = {
              zipcode: selectedAddress.zipcode,
              street: address.street,
              neighborhood: address.neighborhood,
              city: address.city,
              state: address.state,
              number: selectedAddress.number,
              complement: selectedAddress.complement,
            };

            this.setState(state => ({
              propertyAccessAddressCities: fetchedCities,
              propertyAccessAddressNeighborhoods: fetchedNeighborhoods,
              propertyAccessAddressSelectedState: selectedState,
              propertyAccessAddressSelectedCity: selectedCity,
              propertyAccessAddressSelectedNeighborhood: selectedNeighborhood,

              formData: update(state.formData, {
                propertyAccess: {
                  address: { $set: updatedAddress },
                  realEstateAddress: { $set: realEstateId },
                },
              }),

              schema: update(state.schema, {
                definitions: {
                  propertyAccess: {
                    properties: {
                      address: {
                        $set: {
                          properties: {
                            ...state.schema.definitions.address.properties,
                            city: {
                              enum: fetchedCities?.map(option => option?.name) || [],
                              enumNames: fetchedCities?.map(option => option?.name) || [],
                            },
                            neighborhood: {
                              enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                              enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                            },
                          },
                        },
                      },
                    },
                  },
                },
              }),
            }));

            isPropertyAccessRealEstateAddressChangedByUserSelection = true;
          },
        });
      }
    }

    if (
      formData.propertyAccess?.devolutionRealEstateAddress &&
      formData.propertyAccess.devolutionRealEstateAddress !==
        formDataState.propertyAccess?.devolutionRealEstateAddress &&
      formDataState.propertyAccess?.keysDevolutionType === 'Na imobiliária'
    ) {
      const selectedRealEstate = this.state.realEstateAddresses?.find(
        realEstate => realEstate.public_id === formData.propertyAccess.devolutionRealEstateAddress
      );

      if (selectedRealEstate) {
        const { address: selectedAddress, public_id: realEstateId } = selectedRealEstate;

        await this.handleZipcodeFieldChange({
          address: selectedAddress,
          handleSetAddressDataInState: ({
            address,
            fetchedCities,
            fetchedNeighborhoods,
            selectedState,
            selectedCity,
            selectedNeighborhood,
          }) => {
            const updatedAddress = {
              zipcode: selectedAddress.zipcode,
              street: address.street,
              neighborhood: address.neighborhood,
              city: address.city,
              state: address.state,
              number: selectedAddress.number,
              complement: selectedAddress.complement,
            };

            this.setState(state => ({
              propertyAccessKeysDevolutionAddressCities: fetchedCities,
              propertyAccessKeysDevolutionAddressNeighborhoods: fetchedNeighborhoods,
              propertyAccessKeysDevolutionAddressSelectedState: selectedState,
              propertyAccessKeysDevolutionAddressSelectedCity: selectedCity,
              propertyAccessKeysDevolutionAddressSelectedNeighborhood: selectedNeighborhood,

              formData: update(state.formData, {
                propertyAccess: {
                  keysDevolutionAddress: { $set: updatedAddress },
                  devolutionRealEstateAddress: { $set: realEstateId },
                },
              }),

              schema: update(state.schema, {
                definitions: {
                  propertyAccess: {
                    properties: {
                      keysDevolutionAddress: {
                        $set: {
                          properties: {
                            ...state.schema.definitions.address.properties,
                            city: {
                              enum: fetchedCities?.map(option => option?.name) || [],
                              enumNames: fetchedCities?.map(option => option?.name) || [],
                            },
                            neighborhood: {
                              enum: fetchedNeighborhoods?.map(option => option?.name) || [],
                              enumNames: fetchedNeighborhoods?.map(option => option?.name) || [],
                            },
                          },
                        },
                      },
                    },
                  },
                },
              }),
            }));

            isPropertyAccessRealEstateAddressChangedByUserSelection = true;
          },
        });
      }
    }

    if (
      !isAccessInformationRealEstateAddressChangedByUserSelection &&
      !isPropertyAccessRealEstateAddressChangedByUserSelection &&
      !addressesFieldsChangedByZipcodeRequest
    ) {
      this.setState({ formData: { ...formData, ...addressFieldsToEmpty } });
    }

    if (calculatePrice) {
      clearTimeout(this.onChangeTimeout);

      this.onChangeTimeout = setTimeout(() => {
        this.calculateOrderPrice(formData);
      }, 500);
    }

    handleError(errors.length || 0);
  };

  handleSetAddressByOrderData = async ({ address = {}, handleSetAddressDataInState }) => {
    const selectedState = this.state.addressesStates.find(state => state?.name === address?.state);

    const fetchedCities = await this.addressService.getCitiesByState(selectedState?.id);
    const uniqueCities = removeDuplicates(fetchedCities || []);
    const cityId = uniqueCities.find(city => city?.name === address?.city)?.id;
    const selectedCity = uniqueCities.find(city => city?.id === cityId);

    const fetchedNeighborhoods = await this.addressService.getNeighborhoodsByCity(cityId);
    const uniqueNeighborhoods = removeDuplicates(fetchedNeighborhoods || []);
    const neighborhoodId = uniqueNeighborhoods.find(neighborhood => neighborhood?.name === address?.neighborhood)?.id;
    let selectedNeighborhood = uniqueNeighborhoods.find(neighborhood => neighborhood?.id === neighborhoodId);

    if (!selectedNeighborhood) {
      selectedNeighborhood = uniqueNeighborhoods.find(neighborhood => neighborhood?.name === address?.neighborhood);
    }

    handleSetAddressDataInState({
      address,
      fetchedCities: uniqueCities,
      fetchedNeighborhoods: uniqueNeighborhoods,
      selectedState: selectedState?.name ? { label: selectedState.name, value: selectedState.name } : null,
      selectedCity: selectedCity?.name ? { label: selectedCity.name, value: selectedCity.name } : null,
      selectedNeighborhood: selectedNeighborhood?.name
        ? { label: selectedNeighborhood.name, value: selectedNeighborhood.name }
        : null,
    });
  };

  handleZipcodeFieldChange = async ({ zipcode = null, address = {}, handleSetAddressDataInState }) => {
    let stateId = address?.['state_id'];
    let cityId = address?.['city_id'];
    let neighborhoodId = address?.['neighborhood_id'];

    try {
      if (!stateId || !cityId || !neighborhoodId) {
        const fetchedAddress = await this.addressService.getLocationByZipcode(zipcode);

        // Tries to set addresses fields using order remote data
        if (fetchedAddress === null || fetchedAddress?.error === AddressService.errors.LOCATION_NOT_FOUND) {
          await this.handleSetAddressByOrderData({ address, handleSetAddressDataInState });

          return;
        } else if (!fetchedAddress) {
          return;
        }

        address = fetchedAddress;
        stateId = fetchedAddress?.state_id;
        cityId = fetchedAddress?.city_id;
        neighborhoodId = fetchedAddress?.neighborhood_id;
      }

      const fetchedCities = await this.addressService.getCitiesByState(stateId);
      const fetchedNeighborhoods = await this.addressService.getNeighborhoodsByCity(cityId);

      const uniqueCities = removeDuplicates(fetchedCities || []);
      const uniqueNeighborhoods = removeDuplicates(fetchedNeighborhoods || []);

      const selectedState = this.state.addressesStates.find(state => state?.id === stateId);
      const selectedCity = uniqueCities.find(city => city?.id === cityId);
      let selectedNeighborhood = uniqueNeighborhoods.find(neighborhood => neighborhood?.id === neighborhoodId);

      if (!selectedNeighborhood) {
        selectedNeighborhood = uniqueNeighborhoods.find(neighborhood => neighborhood?.name === address?.neighborhood);
      }

      handleSetAddressDataInState({
        address,
        fetchedCities: uniqueCities,
        fetchedNeighborhoods: uniqueNeighborhoods,
        selectedState: selectedState?.name ? { label: selectedState.name, value: selectedState.name } : null,
        selectedCity: selectedCity?.name ? { label: selectedCity.name, value: selectedCity.name } : null,
        selectedNeighborhood: selectedNeighborhood?.name
          ? { label: selectedNeighborhood.name, value: selectedNeighborhood.name }
          : null,
      });
    } catch (err) {
      console.debug(`[DEBUG] handleZipcodeFieldChange error with: ${JSON.stringify(address || {})} `, err);
    }
  };

  handleStateFieldChange = async ({ stateName, handleSetSelectedState, handleSetCitiesInState }) => {
    if (!stateName) return;

    const selectedState = this.state.addressesStates?.find(addressState => addressState?.name === stateName);

    if (selectedState?.id && selectedState?.name) {
      handleSetSelectedState({ label: selectedState.name, value: selectedState.name });

      const fetchedCities = await this.addressService.getCitiesByState(selectedState.id);

      handleSetCitiesInState(removeDuplicates(fetchedCities || []));
    } else {
      handleSetCitiesInState([]);
    }
  };

  handleCityFieldChange = async ({ cityName, citiesInState, handleSetNeighborhoodsInState }) => {
    if (!cityName) return;

    const selectedCity = citiesInState?.find(city => city?.name === cityName);

    if (selectedCity?.id && selectedCity?.name) {
      const fetchedNeighborhoods = await this.addressService.getNeighborhoodsByCity(selectedCity.id);

      handleSetNeighborhoodsInState({
        fetchedNeighborhoods: removeDuplicates(fetchedNeighborhoods || []),
        selectedCity: { label: selectedCity.name, value: selectedCity.name },
      });
    } else {
      handleSetNeighborhoodsInState({ fetchedNeighborhoods: [], selectedCity: null });
    }
  };

  handleNeighborhoodFieldChange = ({
    neighborhoodName,
    neighborhoodsInState,
    handleSetSelectedNeighborhoodInState,
  }) => {
    if (!neighborhoodName) return;

    const selectedNeighborhood = neighborhoodsInState?.find(neighborhood => neighborhood?.name === neighborhoodName);

    handleSetSelectedNeighborhoodInState(
      selectedNeighborhood?.name ? { label: selectedNeighborhood.name, value: selectedNeighborhood.name } : null
    );
  };

  onError = errors => {
    const { handleError } = this.props;

    if (errors?.length > 0) {
      console.debug('[DEBUG] OrderForm errors: ', errors);

      handleError(errors.length);
    }
  };

  calculateOrderPrice = async (formData, customUiSchema = null) => {
    const { uiSchema: uiSchemaState } = this.state;

    this.setState({ priceCalculated: true });

    try {
      const uiSchema = customUiSchema || uiSchemaState;
      const currentPrice = formData?.details && formData?.details?.price;
      const formDataNoPrice = update(formData, { details: { $unset: ['price'] } });

      const { data } = await API.post('/financial/orderprice', JSON.stringify(formDataNoPrice));

      switch (data?.description) {
        case 'STANDARD_METER':
          // Set the price to the given value
          return this.handleAreaPrice({ value: data?.value, uiSchema, formData });

        case 'MONETARY':
          // Enable the field for user input
          return this.handleEnableCustomPrice({
            value: currentPrice,
            minimalPrice: data?.minimalPrice,
            uiSchema,
            formData,
          });

        default:
          return this.handleStringPrice(uiSchema);
      }
    } catch (err) {
      console.debug('[DEBUG] calculateOrderPrice error ', err);
    }
  };

  /**
   * @description Function to enable the price field, for custom price.
   */
  handleEnableCustomPrice = ({ value, minimalPrice, uiSchema, formData = null }) => {
    const { formData: formDataState, priceType } = this.state;
    const formDataToUpdate = formData || formDataState;

    if (priceType !== 'MONETARY') {
      const updatedState = {
        uiSchema: update(uiSchema, { details: { price: { 'ui:disabled': { $set: false } } } }),
        formData: update(formDataToUpdate, { details: { price: { $set: value } } }),
        minimalPrice,
        priceType: 'MONETARY',
      };

      this.setState(updatedState);

      return updatedState;
    }
  };

  /**
   * @description Function to set the price field to be defined string.
   */
  handleStringPrice = uiSchema => {
    const stringUI = {
      'ui:widget': 'customPriceStringWidget',
      'ui:options': { label: false },
    };

    const updatedState = {
      uiSchema: update(uiSchema, { details: { price: { $set: stringUI } } }),
      minimalPrice: null,
      priceType: 'PENDING',
    };

    this.setState(updatedState);

    return updatedState;
  };

  /**
   * @description Function to set the price field to be in meters.
   */
  handleAreaPrice = ({ value, uiSchema, formData = null }) => {
    const { formData: formDataState } = this.state;
    const formDataToUpdate = formData || formDataState;

    const updatedState = {
      minimalPrice: null,
      uiSchema: update(uiSchema, { details: { price: { 'ui:disabled': { $set: true } } } }),
      formData: update(formDataToUpdate, { details: { price: { $set: value } } }),
      priceType: 'STANDARD_METER',
    };

    this.setState(updatedState);

    return updatedState;
  };

  validate = (formData, errors) => {
    const { formData: formDataState, minimalPrice } = this.state;
    const { intl } = this.props;

    if (minimalPrice !== null) {
      if (formData.details?.price < minimalPrice || formData.details?.price === undefined) {
        errors.details.price.addError(intl.formatMessage({ id: 'minimumPrice' }, { meters: minimalPrice }));
      }
    } else if (formDataState && formDataState.recipients) {
      formDataState.recipients?.forEach((data, index) => {
        if (data.email && !data.email?.includes('@')) {
          errors.recipients[index].email.addError(intl.formatMessage({ id: 'requiredEmail' }));
        }
      });
    }

    return errors;
  };

  transformErrors = errors => {
    const { intl } = this.props;

    return errors
      .filter(error => error.name !== 'enum' && error.name !== 'oneOf')
      .map(error => {
        const errorMessageId = this.transformErrorsMessages[error.name] || null;

        if (errorMessageId) {
          error.message = intl.formatMessage({ id: errorMessageId });

          if (error.name === 'format' && error.params?.format === 'date-with-time') {
            error.message = intl.formatMessage({ id: 'invalidDateTimeFormat' });
          }

          if (error.name === 'type' && error.params?.type === 'array') {
            error.message = intl.formatMessage({ id: 'shouldBeAnArray' });
          }
        }

        return error;
      });
  };

  objectFieldTemplate = ({ TitleField, properties, title }) => (
    <div>
      {!!title && !this.objectFieldTemplateForbiddenTitles.includes(title) && <TitleField title={title} />}

      <Grid container style={this.styles.content}>
        {properties.map(prop => {
          if (prop.content?.props?.uiSchema?.['ui:widget'] === 'hidden') return null;

          let className = containerClasses.schemaObjectContainer;

          if (prop.content?.props?.uiSchema?.['ui:options']?.indented) {
            className = containerClasses.schemaObjectContainer;
          } else {
            className = this.objectFieldTemplateClassNames[prop.name] || 'col-md-12';
          }

          return (
            <div className={className} key={prop.content?.key}>
              {prop.content}
            </div>
          );
        })}
      </Grid>
    </div>
  );

  customFieldTemplate = ({ classNames, help, description, errors, children, label }) => {
    if (
      ['acAddress', 'paAddress', 'keysDevolutionAddress', 'income', 'main', 'desiredPropertyAddress'].includes(label)
    ) {
      return <div className={containerClasses.removeNested}>{children}</div>;
    }

    return (
      <div className={classNames}>
        {description}

        {children}

        <div style={this.styles.error}>{errors}</div>

        {help}
      </div>
    );
  };

  customTitleField = ({ title, required }) => {
    if (
      title === 'schedule' ||
      title === 'acAddress' ||
      title === 'paAddress' ||
      title === 'address' ||
      title === 'keysDevolutionAddress' ||
      title === 'income' ||
      title === 'main' ||
      title === 'desiredPropertyAddress'
    ) {
      return null;
    }

    try {
      const titleIcon = this.customTitleFieldIcons?.[title] || null;
      let legend = required ? `${(<FormattedMessage id={title} />)}*` : <FormattedMessage id={title} />;

      if (title === 'details') {
        legend = required ? (
          `${(<FormattedMessage id={`${title}_title`} />)}*`
        ) : (
          <FormattedMessage id={`${title}_title`} />
        );
      }

      return (
        <div id="custom">
          <Grid container alignContent="center" spacing={8}>
            <Grid item>{titleIcon}</Grid>

            <Grid item style={this.styles.title}>
              {legend}
            </Grid>
          </Grid>
        </div>
      );
    } catch {
      return null;
    }
  };

  createOption = optionValue => ({ id: optionValue, name: optionValue, tokenized: optionValue });

  addressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.address?.properties?.neighborhood?.enum) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      addressNeighborhoods: [newOption, ...state.addressNeighborhoods],
      addressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      formData: update(state.formData, { address: { neighborhood: { $set: newOption.name } } }),
      schema: update(state.schema, {
        definitions: {
          address: {
            properties: {
              neighborhood: { enum: { $unshift: [newOption.name] }, enumNames: { $unshift: [newOption.name] } },
            },
          },
        },
      }),
    }));
  };

  accessInformationAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.accessInformation?.properties?.address?.properties?.neighborhood?.enum) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      accessInformationAddressNeighborhoods: [newOption, ...state.accessInformationAddressNeighborhoods],
      accessInformationAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      formData: update(state.formData, { accessInformation: { address: { neighborhood: { $set: newOption.name } } } }),
      schema: update(state.schema, {
        definitions: {
          accessInformation: {
            properties: {
              address: {
                properties: {
                  neighborhood: { enum: { $unshift: [newOption.name] }, enumNames: { $unshift: [newOption.name] } },
                },
              },
            },
          },
        },
      }),
    }));
  };

  accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    const { schema } = this.state;

    if (!schema?.definitions?.accessInformation?.properties?.keysDevolutionAddress?.properties?.neighborhood?.enum) {
      return;
    }

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      accessInformationKeysDevolutionAddressNeighborhoods: [
        newOption,
        ...state.accessInformationKeysDevolutionAddressNeighborhoods,
      ],
      accessInformationKeysDevolutionAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      formData: update(state.formData, {
        accessInformation: { keysDevolutionAddress: { neighborhood: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          accessInformation: {
            properties: {
              keysDevolutionAddress: {
                properties: {
                  neighborhood: { enum: { $unshift: [newOption.name] }, enumNames: { $unshift: [newOption.name] } },
                },
              },
            },
          },
        },
      }),
    }));
  };

  propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.propertyAccess?.properties?.address?.properties?.neighborhood?.enum) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      propertyAccessAddressNeighborhoods: [newOption, ...state.propertyAccessAddressNeighborhoods],
      propertyAccessAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      formData: update(state.formData, { propertyAccess: { address: { neighborhood: { $set: newOption.name } } } }),
      schema: update(state.schema, {
        definitions: {
          propertyAccess: {
            properties: {
              address: {
                properties: {
                  neighborhood: { enum: { $unshift: [newOption.name] }, enumNames: { $unshift: [newOption.name] } },
                },
              },
            },
          },
        },
      }),
    }));
  };

  propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    const { schema } = this.state;

    if (!schema?.definitions?.propertyAccess?.properties?.keysDevolutionAddress?.properties?.neighborhood?.enum) {
      return;
    }

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      propertyAccessKeysDevolutionAddressNeighborhoods: [
        newOption,
        ...state.propertyAccessKeysDevolutionAddressNeighborhoods,
      ],
      propertyAccessKeysDevolutionAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      formData: update(state.formData, {
        propertyAccess: { keysDevolutionAddress: { neighborhood: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          propertyAccess: {
            properties: {
              keysDevolutionAddress: {
                properties: {
                  neighborhood: { enum: { $unshift: [newOption.name] }, enumNames: { $unshift: [newOption.name] } },
                },
              },
            },
          },
        },
      }),
    }));
  };

  desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.desired_property_address?.properties?.neighborhood?.enum) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      desiredPropertyAddressNeighborhoods: [newOption, ...state.desiredPropertyAddressNeighborhoods],
      desiredPropertyAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      formData: update(state.formData, {
        desired_property: { address: { neighborhood: { $set: newOption.name } } },
      }),
      schema: update(state.schema, {
        definitions: {
          desired_property_address: {
            properties: {
              neighborhood: { enum: { $unshift: [newOption.name] }, enumNames: { $unshift: [newOption.name] } },
            },
          },
        },
      }),
    }));
  };

  mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption = (optionValue = '') => {
    if (!this.state.schema?.definitions?.main_suitor_address?.properties?.neighborhood?.enum) return;

    const newOption = this.createOption(optionValue);

    this.setState(state => ({
      mainSuitorAddressNeighborhoods: [newOption, ...state.mainSuitorAddressNeighborhoods],
      mainSuitorAddressSelectedNeighborhood: { label: newOption.name, value: newOption.name },
      formData: update(state.formData, { main_suitor_address: { neighborhood: { $set: newOption.name } } }),
      schema: update(state.schema, {
        definitions: {
          main_suitor_address: {
            properties: {
              neighborhood: { enum: { $unshift: [newOption.name] }, enumNames: { $unshift: [newOption.name] } },
            },
          },
        },
      }),
    }));
  };

  render() {
    const {
      loading,
      uiSchema,
      schema,
      formData,
      fields,

      addressSelectedState,
      addressSelectedCity,
      addressSelectedNeighborhood,

      accessInformationAddressSelectedState,
      accessInformationAddressSelectedCity,
      accessInformationAddressSelectedNeighborhood,

      accessInformationKeysDevolutionAddressSelectedState,
      accessInformationKeysDevolutionAddressSelectedCity,
      accessInformationKeysDevolutionAddressSelectedNeighborhood,

      propertyAccessAddressSelectedState,
      propertyAccessAddressSelectedCity,
      propertyAccessAddressSelectedNeighborhood,

      propertyAccessKeysDevolutionAddressSelectedState,
      propertyAccessKeysDevolutionAddressSelectedCity,
      propertyAccessKeysDevolutionAddressSelectedNeighborhood,

      desiredPropertyAddressSelectedState,
      desiredPropertyAddressSelectedCity,
      desiredPropertyAddressSelectedNeighborhood,

      mainSuitorAddressSelectedState,
      mainSuitorAddressSelectedCity,
      mainSuitorAddressSelectedNeighborhood,
    } = this.state;
    const { onSubmit, orderType, submitted } = this.props;

    const widgets = makeWidgets({
      addressSelectedState,
      addressSelectedCity,
      addressSelectedNeighborhood,

      accessInformationAddressSelectedState,
      accessInformationAddressSelectedCity,
      accessInformationAddressSelectedNeighborhood,

      accessInformationKeysDevolutionAddressSelectedState,
      accessInformationKeysDevolutionAddressSelectedCity,
      accessInformationKeysDevolutionAddressSelectedNeighborhood,

      propertyAccessAddressSelectedState,
      propertyAccessAddressSelectedCity,
      propertyAccessAddressSelectedNeighborhood,

      propertyAccessKeysDevolutionAddressSelectedState,
      propertyAccessKeysDevolutionAddressSelectedCity,
      propertyAccessKeysDevolutionAddressSelectedNeighborhood,

      desiredPropertyAddressSelectedState,
      desiredPropertyAddressSelectedCity,
      desiredPropertyAddressSelectedNeighborhood,

      mainSuitorAddressSelectedState,
      mainSuitorAddressSelectedCity,
      mainSuitorAddressSelectedNeighborhood,

      addressNeighborhoodSelectWidgetOnCreateOption: this.addressNeighborhoodSelectWidgetOnCreateOption,
      accessInformationAddressNeighborhoodSelectWidgetOnCreateOption:
        this.accessInformationAddressNeighborhoodSelectWidgetOnCreateOption,
      accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption:
        this.accessInformationKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption,
      propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption:
        this.propertyAccessAddressNeighborhoodSelectWidgetOnCreateOption,
      propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption:
        this.propertyAccessKeysDevolutionAddressNeighborhoodSelectWidgetOnCreateOption,
      desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption:
        this.desiredPropertyAddressNeighborhoodSelectWidgetOnCreateOption,
      mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption: this.mainSuitorAddressNeighborhoodSelectWidgetOnCreateOption,
    });

    if (loading) return <Loading borderColor="#fff" paperBackground="#fff" />;

    return (
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        onSubmit={onSubmit}
        onChange={this.handleChange}
        ArrayFieldTemplate={orderType === 'marketplace_fichacerta' ? SuitorsArrayFieldTemplate : ArrayFieldTemplate}
        ObjectFieldTemplate={this.objectFieldTemplate}
        fields={fields}
        widgets={widgets}
        liveValidate={submitted}
        showErrorList={false}
        transformErrors={this.transformErrors}
        onError={this.onError}
        FieldTemplate={this.customFieldTemplate}
        validate={this.validate}
        customFormats={this.customFormats}
      >
        <button
          type="submit"
          data-cy="order-form-submit"
          ref={btn => {
            this.submitButton = btn;
          }}
          hidden
        />
      </Form>
    );
  }
}

OrderForm.propTypes = {
  formData: PropTypes.object,
  handleError: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  previousOrderFile: PropTypes.string,
  schema: PropTypes.object,
  submitted: PropTypes.bool.isRequired,
  uiSchema: PropTypes.object,
};

OrderForm.defaultProps = {
  formData: {},
  previousOrderFile: null,
  schema: {},
  uiSchema: {},
};

export default injectIntl(OrderForm, { forwardRef: true });
